<aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a routerLink="/dashboard" class="brand-link" *ngIf="dataOperator == null && roleAdmin != 'Filter'">
        <img src="assets/adminlte/dist/img/AdminLTELogo.png" alt="AdminLTE Logo"
            class="brand-image img-circle elevation-3" style="opacity: .8">
        <span class="brand-text font-weight-light">{{roleAdmin}}</span>
    </a>
    <a routerLink="/check-smartwatch-ticker/{{adminId}}" class="brand-link" *ngIf="dataOperator == null && roleAdmin == 'Filter'">
        <img src="assets/adminlte/dist/img/AdminLTELogo.png" alt="AdminLTE Logo"
            class="brand-image img-circle elevation-3" style="opacity: .8">
        <span class="brand-text font-weight-light">{{roleAdmin}}</span>
    </a>
    <a routerLink="/detailOperator/{{adminId}}" class="brand-link" *ngIf="dataOperator != null">
        <img src="assets/adminlte/dist/img/AdminLTELogo.png" alt="AdminLTE Logo"
            class="brand-image img-circle elevation-3" style="opacity: .8">
        <span class="brand-text font-weight-light">{{roleAdmin}}</span>
    </a>

    <!-- Sidebar -->
    <div
        class="sidebar os-host os-theme-light os-host-overflow os-host-overflow-y os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-transition">
        <div class="os-resize-observer-host">
            <div class="os-resize-observer observed" style="left: 0px; right: auto;"></div>
        </div>
        <div class="os-size-auto-observer" style="height: calc(100% + 1px); float: left;">
            <div class="os-resize-observer observed"></div>
        </div>
        <div class="os-content-glue" style="margin: 0px -8px; width: 249px; height: 655px;"></div>
        <div class="os-padding">
            <div class="os-viewport os-viewport-native-scrollbars-invisible" style="overflow-y: scroll;">
                <div class="os-content" style="padding: 0px 8px; height: 100%; width: 100%;">
                    <!-- Sidebar user panel (optional) -->
                    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
                        <div class="image" *ngIf="dataOperator != null">
                            <div
                                *ngIf="dataOperator.operatorPic != null &&  dataOperator.operatorPic != ''; else elseImg">
                                <img src="{{imageUrl1}}/{{dataOperator.operatorPic}}"  onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                                    class="img-circle elevation-2" alt="img-profile"
                                    style="width: 40px;height: 40px;object-fit: cover;">

                            </div>
                            <ng-template #elseImg>
                                <img src="assets/adminlte/dist/img/nouser.svg" class="img-circle elevation-2"
                                    alt="img-profile" style="width: 40px;height: 40px;object-fit: cover;">
                            </ng-template>
                            <!-- <img *ngIf="dataOperator.operatorPic != null"
                                src="{{imageUrl1}}/{{dataOperator.operatorPic}}"
                                class="img-circle elevation-2" alt="User Image"
                                style="width: 40px;height: 40px;object-fit: cover;">
                            <img *ngIf="dataOperator.operatorPic == null" src="assets/adminlte/dist/img/nouser.svg"
                                class="img-circle elevation-2" alt="User Image"
                                style="width: 40px;height: 40px;object-fit: cover;"> -->
                        </div>
                        <div class="image" *ngIf="dataOperator == null">

                                <img src="assets/adminlte/dist/img/nouser.svg" class="img-circle elevation-2"
                                    alt="img-profile" style="width: 40px;height: 40px;object-fit: cover;">

                        </div>
                        <div class="info" style="margin-top: auto;margin-bottom: auto;">
                            <a routerLink="/detailOperator/{{adminId}}" class="d-block"
                                *ngIf="dataOperator != null">{{dataOperator.operatorUsername}}</a>
                            <a routerLink="/dashboard" class="d-block" *ngIf="dataOperator == null && roleAdmin != 'Filter'">Admin</a>
                            <a routerLink="/check-smartwatch-ticker/{{adminId}}" class="d-block" *ngIf="roleAdmin == 'Filter'">Admin Filter</a>
                        </div>
                    </div>

                    <!-- Sidebar Menu -->
                    <nav class="mt-2" *ngIf="idOperator != null">
                        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                            data-accordion="false">
                            <li class="nav-header">Menu</li>
                            <li class="nav-item">
                                <a routerLinkActive="active" routerLink="/dashboard" class="nav-link" *ngIf="roleAdmin == 'SuperAdmin'">
                                    <i class="nav-icon fas fa-th"></i>
                                    <p>
                                        Dashboard
                                    </p>
                                    <!-- <p class="right badge badge-danger">New</p> -->

                                </a>
                            </li>
                            <li class="nav-item" *ngIf="showHeadSuperAdmin" [ngClass]="{'menu-is-opening menu-open': activeMenu === 'roles'}">
                              <a href="javascript:void(0)" class="nav-link" (click)="toggleMenu('roles')">
                                  <i class="nav-icon fas fa-plus"></i>
                                  <p>
                                      Create Role
                                      <i class="right fas fa-angle-left"></i>
                                  </p>
                              </a>
                              <ul class="nav nav-treeview" [ngClass]="{'menu-is-opening menu-open': activeMenu === 'roles'}">
                                <li class="nav-item" *ngIf="showHeadSuperAdmin">
                                  <a routerLinkActive="active" routerLink="/admin-list" class="nav-link">
                                    <div class="ml-4">
                                      <i class="nav-icon fas fa-users-cog"></i>
                                      <p>Admin Portal</p>
                                    </div>
                                  </a>
                                </li>
                                <li class="nav-item" *ngIf="showAdminFilter">
                                    <a routerLinkActive="active" routerLink="/admin-filter-list" class="nav-link">
                                      <div class="ml-4">
                                        <i class="nav-icon fas fa-user-tag"></i>
                                        <p>Admin Filter</p>
                                      </div>
                                    </a>
                                </li>
                                <li class="nav-item" *ngIf="showAdminViewOnly">
                                    <a routerLinkActive="active" routerLink="/admin-view-only" class="nav-link">
                                      <div class="ml-4">
                                        <i class="nav-icon fas fa-user-secret"></i>
                                        <p>Admin View Only</p>
                                      </div>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a routerLinkActive="active" routerLink="/whitelist" class="nav-link">
                                      <div class="ml-4">
                                        <i class="nav-icon fas fa-user-tie"></i>
                                        <p>Whitelist</p>
                                      </div>
                                    </a>
                                </li>
                              </ul>
                            </li>
                            <li class="nav-item" [ngClass]="{'menu-is-opening menu-open': activeMenu === 'watches'}" *ngIf="showHeadSuperAdmin">
                                <a href="javascript:void(0)" class="nav-link d-flex align-items-center" (click)="toggleMenu('watches')">
                                    <mat-icon>watch</mat-icon>
                                    <p class="ml-2">
                                        Watches
                                        <i class="right fas fa-angle-left"></i>
                                    </p>
                                </a>
                                <ul class="nav nav-treeview" [ngClass]="{'menu-is-opening menu-open': activeMenu === 'watches'}">
                                  <li class="nav-item" *ngIf="showWatchStatus">
                                    <a routerLinkActive="active" routerLink="/watch-status" class="nav-link">
                                      <div class="ml-4">
                                        <i class="nav-icon fas fa-clock"></i>
                                        <p>Watch Status</p>
                                      </div>
                                    </a>
                                  </li>
                                  <li class="nav-item" *ngIf="showWatchManagement">
                                      <a routerLinkActive="active" routerLink="/watch-management" class="nav-link">
                                        <div class="ml-4">
                                          <i class="nav-icon fas fa-clipboard"></i>
                                          <p>Watch Management</p>
                                        </div>
                                      </a>
                                  </li>
                                  <li class="nav-item" *ngIf="show4GWatches">
                                      <a routerLinkActive="active" routerLink="/4g-watches" class="nav-link">
                                        <div class="ml-4">
                                          <i class="nav-icon fas fa-stopwatch"></i>
                                          <p>4G Watches</p>
                                        </div>
                                      </a>
                                  </li>
                                </ul>
                            </li>
                            <li class="nav-item" *ngIf="showHeadSuperAdmin">
                                <a routerLinkActive="active" routerLink="/device-type" class="nav-link">
                                    <i class="nav-icon fas fa-sim-card"></i>
                                    <p>
                                        Manage Device Types
                                        <!-- <span class="right badge badge-danger">New</span> -->
                                    </p>
                                </a>
                            </li>
                            <li class="nav-item" *ngIf="hideLiveMonitor">
                                <a routerLinkActive="active" routerLink="/users-list/{{idOperator}}" class="nav-link">
                                    <i class="nav-icon fas fa-users"></i>
                                    <p>
                                        All User
                                        <!-- <span class="right badge badge-danger">New</span> -->
                                    </p>
                                </a>
                            </li>
                            <li class="nav-item" *ngIf="showSmartwatchTicker">
                                <a routerLinkActive="active" routerLink="/check-smartwatch-ticker/{{adminId}}" class="nav-link">
                                    <i class="nav-icon fas fa-thermometer-half"></i>
                                    <p>
                                        Check Smartwatch Ticker
                                        <!-- <span class="right badge badge-danger">New</span> -->
                                    </p>
                                </a>
                            </li>
                            <li class="nav-item" *ngIf="showSmartwatchTicker">
                                <a routerLinkActive="active" routerLink="/ticker-log" class="nav-link">
                                    <i class="nav-icon fas fa-file-alt"></i>
                                    <p>
                                        Ticker Log
                                        <!-- <span class="right badge badge-danger">New</span> -->
                                    </p>
                                </a>
                            </li>
                            <li class="nav-item" *ngIf="showHeadAdminProvince">
                                <a routerLinkActive="active" class="nav-link"
                                    routerLink="/adminDistrict-list/{{adminNamerole}}/{{adminProvinceID}}/{{adminId}}">
                                    <i class="nav-icon fas fa-users-cog"></i>
                                    <p>
                                        Admin list
                                        <!-- <span class="right badge badge-danger">New</span> -->
                                    </p>
                                </a>
                            </li>
                            <li class="nav-item" *ngIf="showHeadAdminDistrict">
                                <a routerLinkActive="active" class="nav-link" *ngIf="getNameProvince !=null"
                                    routerLink="/adminSubistrict-list/{{getNameProvince}}/{{getProvinceId}}/{{adminNamerole}}/{{adminDistrictId}}/{{adminParrent}}">
                                    <i class="nav-icon fas fa-users-cog"></i>
                                    <p>
                                        Admin list
                                        <!-- <span class="right badge badge-danger">New</span> -->
                                    </p>
                                </a>
                            </li>
                            <li class="nav-item" *ngIf="showHeadAdminSubdistricts">
                                <a routerLinkActive="active" class="nav-link"
                                    *ngIf="getNameProvince !=null && getNameDistrict != null"
                                    routerLink="/operator-list/{{getNameProvince}}/{{getNameDistrict}}/{{adminNamerole}}/{{getIdSubDistrict}}/{{adminParrent}}">
                                    <i class="nav-icon fas fa-users-cog"></i>
                                    <p>
                                        Operator List
                                        <!-- <span class="right badge badge-danger">New</span> -->
                                    </p>
                                </a>
                            </li>
                            <li class="nav-item" *ngIf="hideLiveMonitor">
                                <a routerLinkActive="active" class="nav-link"
                                    *ngIf="getNameProvince !=null && getNameDistrict != null"
                                    routerLink="/caseList">
                                    <i class="nav-icon fas fa-clipboard-list"></i>
                                    <p>
                                        Report SOS Status
                                        <!-- <span class="right badge badge-danger">New</span> -->
                                    </p>
                                </a>
                            </li>
                            <!-- <li class="nav-item" *ngIf="hideLiveMonitor">
                                <a routerLinkActive="active" class="nav-link"
                                    *ngIf="getNameProvince !=null && getNameDistrict != null"
                                    routerLink="/userCovid19List">
                                    <i class="nav-icon fas fa-viruses"></i>
                                    <p>
                                        Report Covid 19 Case
                                    </p>
                                </a>
                            </li> -->
                            <li class="nav-item" *ngIf="showHeadAdminHuawei">
                                <a routerLinkActive="active" class="nav-link"
                                    routerLink="/operator/{{dataAdminLoginin[0].adminNamerole}}/{{dataAdminLoginin[0].adminRole}}/{{dataAdminLoginin[0].adminId}}/{{dataAdminLoginin[0].adminParrent}}">
                                    <i class="nav-icon fas fa-users-cog"></i>
                                    <p>
                                        Operator List
                                        <!-- <span class="right badge badge-danger">New</span> -->
                                    </p>
                                </a>
                            </li>
                            <li class="nav-item" *ngIf="showProfileOperator">
                                <a routerLinkActive="active" class="nav-link" *ngIf="getNameProvince !=null && getNameDistrict != null"
                                    routerLink="/detailOperator/{{idOperator}}">
                                    <i class="nav-icon fas fa-id-card"></i>
                                    <p>
                                        Operator Profile
                                    </p>
                                </a>
                            </li>
                            <li class="nav-item" *ngIf="hideAlertOperator">
                                <a routerLinkActive="active" routerLink="/alerts/{{adminId}}" class="nav-link">
                                    <i class="nav-icon fas fa-bell"></i>
                                    <p>
                                        SOS-Alert
                                        <!-- <p *ngIf="animationItem == true" class="tada right badge badge-danger">NEW </p>
                                        <p *ngIf="animationItem == false" class="right badge badge-danger">NEW</p> -->
                                    </p>

                                </a>
                            </li>
                            <li class="nav-item" *ngIf="showTrackingSystem">
                                <a routerLinkActive="active" routerLink="/tracking-system/{{adminId}}" class="nav-link">
                                    <i class="nav-icon fas fa-ethernet"></i>
                                    <p>
                                        Tracking System
                                        <!-- <p *ngIf="animationItem == true" class="tada right badge badge-danger">NEW </p>
                                        <p *ngIf="animationItem == false" class="right badge badge-danger">NEW</p> -->
                                    </p>

                                </a>
                            </li>

                            <!-- <li class="nav-item" *ngIf="hideAlertOperator">
                                <a href="javascript:void(0)" class="nav-link">
                                    <i class="nav-icon fas fa-user-md"></i>
                                    <p>
                                        Doctor
                                        <i class="right fas fa-angle-left"></i>
                                    </p>
                                </a>
                                <ul class="nav nav-treeview">
                                    <li class="nav-item">
                                        <a routerLinkActive="active" routerLink="/doctorDashboard"
                                            class="nav-link">
                                            <i class="far fa-circle nav-icon"></i>
                                            <p>Doctor Dashboard</p>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a routerLinkActive="active" routerLink="/covidAdminAlert"
                                            class="nav-link">
                                            <i class="far fa-circle nav-icon"></i>
                                            <p>Covid Admin Alert</p>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a routerLinkActive="active" routerLink="/doctorPatientCovid19List"
                                            class="nav-link">
                                            <i class="far fa-circle nav-icon"></i>
                                            <p>Doctor's patients</p>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a routerLinkActive="active" routerLink="/doctorNotificationCovid19List"
                                            class="nav-link">
                                            <i class="far fa-circle nav-icon"></i>
                                            <p>Doctor-Alert</p> -->
                                            <!-- <span class="right badge badge-danger">New 1</span> -->
                                        <!-- </a>
                                    </li>
                                    <li class="nav-item">
                                        <a routerLinkActive="active" routerLink="/treatedPatientsList"
                                            class="nav-link">
                                            <i class="far fa-circle nav-icon"></i>
                                            <p>Treated patients</p>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a routerLinkActive="active" routerLink="/doctorMallCovid19List"
                                            class="nav-link">
                                            <i class="far fa-circle nav-icon"></i>
                                            <p>Unselected Patients</p>
                                        </a>
                                    </li>
                                </ul>
                            </li> -->

                            <li class="nav-item" *ngIf="hideReport" [ngClass]="{'menu-is-opening menu-open': activeMenu === 'reports'}">
                                <a href="javascript:void(0)" class="nav-link" (click)="toggleMenu('reports')">
                                    <i class="nav-icon fas fa-chart-pie"></i>
                                    <p>
                                        Report
                                        <i class="right fas fa-angle-left"></i>
                                    </p>
                                </a>
                                <ul class="nav nav-treeview" [ngClass]="{'menu-is-opening menu-open': activeMenu === 'reports'}">
                                    <li class="nav-item">
                                        <a routerLinkActive="active" routerLink="/ReportUser/{{adminId}}"
                                            class="nav-link">
                                            <div class="ml-4">
                                              <i class="far fa-circle nav-icon"></i>
                                              <p>User</p>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a routerLinkActive="active" routerLink="/ReportJob/{{adminId}}"
                                            class="nav-link">
                                            <div class="ml-4">
                                              <i class="far fa-circle nav-icon"></i>
                                              <p>SOS Job</p>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a routerLinkActive="active" routerLink="/ReportOperator/{{adminId}}"
                                            class="nav-link">
                                            <div class="ml-4">
                                              <i class="far fa-circle nav-icon"></i>
                                              <p>Operator</p>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a routerLinkActive="active" routerLink="/daily-report/{{adminId}}"
                                            class="nav-link">
                                            <div class="ml-4">
                                              <i class="far fa-circle nav-icon"></i>
                                              <p>Daily Report</p>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </li>

                            <li class="nav-item" *ngIf="hideLiveMonitor" [ngClass]="{'menu-is-opening menu-open': activeMenu === 'sos'}">
                                <a href="javascript:void(0)" class="nav-link" (click)="toggleMenu('sos')">
                                    <i class="nav-icon fas fa-desktop"></i>
                                    <p>
                                        SOS Live Monitor
                                        <i class="right fas fa-angle-left"></i>
                                    </p>
                                </a>
                                <ul class="nav nav-treeview" [ngClass]="{'menu-is-opening menu-open': activeMenu === 'sos'}">
                                    <li class="nav-item">
                                        <a routerLinkActive="active" target="_blank" routerLink="/liveMonitor" class="nav-link">
                                          <div class="ml-4">
                                            <i class="far fa-circle nav-icon"></i>
                                            <p>
                                                SOS Alert
                                            </p>
                                          </div>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a routerLinkActive="active" target="_blank" routerLink="/liveMonitorWorking" class="nav-link">
                                          <div class="ml-4">
                                            <i class="far fa-circle nav-icon"></i>
                                            <p>
                                                SOS Working
                                            </p>
                                          </div>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a routerLinkActive="active" target="_blank" routerLink="/liveMonitorSuccess" class="nav-link">
                                          <div class="ml-4">
                                            <i class="far fa-circle nav-icon"></i>
                                            <p>
                                                SOS Success
                                            </p>
                                          </div>
                                        </a>
                                    </li>
                                </ul>
                            </li>

                            <li class="nav-item" *ngIf="showPdf">
                                <a routerLinkActive="active" class="nav-link" routerLink="/approvePdf">
                                    <i class="nav-icon fas fa-file-pdf"></i>
                                    <p>
                                        Approve PDF
                                    </p>
                                </a>
                            </li>

                            <li class="nav-header" *ngIf="hideLiveMonitor">Setting</li>
                            <li class="nav-item" *ngIf="hideLiveMonitor">
                                <a routerLinkActive="active" href="javascript:void(0)" (click)="openDialogEditAdmin()"
                                    class="nav-link">
                                    <i class="nav-icon fas fa-cog"></i>
                                    <p>
                                        Setting
                                        <!-- <span class="right badge badge-danger">New</span> -->
                                    </p>
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <!-- /.sidebar-menu -->
                </div>
            </div>
        </div>
        <div class="os-scrollbar os-scrollbar-horizontal os-scrollbar-unusable os-scrollbar-auto-hidden">
            <div class="os-scrollbar-track">
                <div class="os-scrollbar-handle" style="width: 100%; transform: translate(0px, 0px);"></div>
            </div>
        </div>
        <div class="os-scrollbar os-scrollbar-vertical os-scrollbar-auto-hidden">
            <div class="os-scrollbar-track">
                <div class="os-scrollbar-handle" style="height: 56.8248%; transform: translate(0px, 0px);"></div>
            </div>
        </div>
        <div class="os-scrollbar-corner"></div>
    </div>
    <!-- /.sidebar -->
</aside>
