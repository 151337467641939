import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  LOCALE_ID,
  OnInit,
  ViewChild,
} from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { DatePipe, formatDate } from '@angular/common';
import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';
import { SharedServiceService } from 'src/app/common/shared-service.service';
import { FormControl, FormGroup } from '@angular/forms';
import { GlobalvalDialog } from '../modal/globalval/globalval.component';
import { MatDialog } from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
  selector: 'app-sp-o2',
  templateUrl: './sp-o2.component.html',
  styleUrls: ['./sp-o2.component.scss'],
})
export class SpO2Component implements OnInit {
  httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  ipServer: string = GlobalConstants.imageUrl2;
  TrickerServer: string = GlobalConstants.unknownUrl;

  // ipServer: string = "https://localhost:44378";

  imeiList: any[] = [];

  dataChartSpo2: any[] = [];
  dataCalculateSpo2: any[] = [];
  dataChart24List: any[] = [];
  dataChart24List_new: any[] = [];
  dataChart7DayList: any[] = [];

  dataChart24List_Day1: any[] = [];
  dataCalculateSpo2_Day1: any[] = [];

  filterEmtyData: any[] = [];
  datetimeShowList: any[] = [];

  minSpo2Value: any = 0;
  maxSpo2Value: any = 0;
  averSpo2Value: any = 0;

  countMin: any = 0;
  countMax: any = 0;
  countval8089: any = 0;
  countvalErr: any = 0;

  gimeiCode: any = '';
  dataSpO2All: any = [];

  tickerDataToTableList: any[] = [];
  pageGetDatatype: string = 'spo2';

  rangeDateStartAndEnd = new FormGroup({
    start: new FormControl(new Date()),
    end: new FormControl(new Date()),
  });

  @ViewChild('chartSpo2', { static: false }) chartSpo2!: ElementRef;
  @ViewChild('chartSpo2Coll', { static: false }) chartSpo2Coll!: ElementRef;

  // @ViewChild('chartSpo2Liner', { static: false }) chartSpo2Liner!: ElementRef;

  // @ViewChild('chartSpo2Aver24', { static: false }) chartSpo2Aver24!: ElementRef;
  @ViewChild('chartSpo2Aver24Line', { static: false })
  chartSpo2Aver24Line!: ElementRef;

  // @ViewChild('chartSpo2Aver24PieHaf', { static: false }) chartSpo2Aver24PieHaf!: ElementRef;
  // @ViewChild('chartSpo2Aver24Pie', { static: false }) chartSpo2Aver24Pie!: ElementRef;

  @ViewChild('chartSpo2Day6', { static: false }) chartSpo2Day6!: ElementRef;
  @ViewChild('chartSpo2Day5', { static: false }) chartSpo2Day5!: ElementRef;
  @ViewChild('chartSpo2Day4', { static: false }) chartSpo2Day4!: ElementRef;
  @ViewChild('chartSpo2Day3', { static: false }) chartSpo2Day3!: ElementRef;
  @ViewChild('chartSpo2Day2', { static: false }) chartSpo2Day2!: ElementRef;
  @ViewChild('chartSpo2Day1', { static: false }) chartSpo2Day1!: ElementRef;
  @ViewChild('chartSpo2Day0', { static: false }) chartSpo2Day0!: ElementRef;

  @ViewChild('chartSpo2Aver7day', { static: false })
  chartSpo2Aver7day!: ElementRef;

  @ViewChild('chartSpo2ValPercen', { static: false })
  chartSpo2ValPercen!: ElementRef;

  imei: string = "";
  value: string = "";
  loading: boolean = true;
  setNow: any = new Date();

  constructor(
    private httpClient: HttpClient,
    private sharedServiceService: SharedServiceService,
    @Inject(LOCALE_ID) private locale: string,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private changeDetectRef:ChangeDetectorRef,
    private route: ActivatedRoute,
    private datePipe: DatePipe
  ) {
    this.imei = this.route.snapshot.params["imei"];
    this.value = this.route.snapshot.params["value"];
  }

  ngOnInit(): void {
    this.sharedServiceService.deviceIDValue.subscribe((message) => {
      // console.log("message ID ", message);
      this.loadDataChart(message ? message : this.imei);
    });
    this.loadGobalListData();
    let dateNow = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    setTimeout(async () => {
      this.loading = false;
      await this.onChangeDateTimeEvent(dateNow, true);
    }, 1500);
  }

  onChengDate() {
    var dataStart: string = '';
    var dataEnd: string = '';

    dataStart = this.rangeDateStartAndEnd.value.start;
    dataEnd = this.rangeDateStartAndEnd.value.end;

    if (this.rangeDateStartAndEnd.value.start != null && this.rangeDateStartAndEnd.value.end != null) {
      var dataStart_ = formatDate(dataStart, 'yyyy-MM-dd', this.locale);
      var dataEnd_ = formatDate(dataEnd, 'yyyy-MM-dd', this.locale);

      var newEnd = new Date(dataEnd);
      var newEndAddDay = newEnd.setDate(newEnd.getDate() + 1);
      dataEnd_ = formatDate(newEndAddDay, 'yyyy-MM-dd', this.locale);
      // console.log("dataStart_ ", dataStart_, " | dataEnd_ ", dataEnd_);

      this.filterDate(dataStart_, dataEnd_);
    }
  }

  openDialogGlovbalval(typeGlobal: any): void {
    const dialogRef = this.dialog.open(GlobalvalDialog, {
      width: '700px',
      data: { typeGlobal },
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log('The dialog was closed', result);
      if ((result+"").startsWith('Update')) {
        this.loadGobalListData();
        this._snackBar.open(result, 'Close', {
          duration: 1000
        });
      }
      else{
        this._snackBar.open(result, 'Close', {
          duration: 3000
        });
      }
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////

  g_name: any;
  g_minvalue: any = 0;
  g_maxvalue: any = 0;
  g_diffvalue: any = 0;

  loadGobalValFormAPI() {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.ipServer}/api/smartwatch_ticker/getEditGobalValue?gobaltext=${this.pageGetDatatype}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: 'json' })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  async loadGobalListData() {
    await this.loadGobalValFormAPI().then(
      (res: any) => {
        this.g_name = res['swgvName'];
        this.g_minvalue = res['swgvMinValue'];
        this.g_maxvalue = res['swgvMaxValue'];
        this.g_diffvalue = res['swgvDifferenceValue'];
      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
      }
    );
  }

  setGlobalValue(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.ipServer}/api/smartwatch_ticker/setEditInfoValue`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: 'text',
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  saveGobalDataValue(minval: any, maxval: any, diffval: any) {
    // console.log('save ', minval, maxval, diffval);

    var jsonData = {
      SwgvName: 'bodytemp',
      SwgvMinValue: minval,
      SwgvMaxValue: maxval,
      SwgvDifferenceValue: diffval,
    };

    this.setGlobalValue(jsonData).then(
      (res: any) => {
        this.loadGobalListData();
        // console.log(res.data);
      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
      }
    );
  }

  //////////////////////////////////////////////////////////

  loadIMEIListFormAPI() {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.ipServer}/api/smartwatch_cn_api/getCN_Spo2_CollectionList`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: 'json' })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  loadChartFormAPI(imeiCode: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.ipServer}/api/smartwatch_cn_api/getCN_LogSpo2List?imeicode=${imeiCode}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: 'json' })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  async loadDataTickerLogToTable(imeiCode: string, datetimes: string) {
    await this.loadTickerLog(imeiCode, datetimes).then(
      (resq: any) => {
        this.tickerDataToTableList = [];
        this.tickerDataToTableList = resq;
      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
      }
    );
  }

  loadTickerLog(imeiCode: string, datetimes: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.ipServer}/api/smartwatch_ticker/getlogTickerList?imei=${imeiCode}&type=${this.pageGetDatatype}&date=${datetimes}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: 'json' })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  async loadDataChart(imeiCode: string) {
    await this.loadChartFormAPI(imeiCode).then(
      async (resq: any) => {
        // console.log("resq -------------> ", resq);
        var res = [];
        this.gimeiCode = imeiCode;

        for (var i = 0; i < resq.length; i++) {
          if (resq[i].spo2_val >= 50) {
            res.push({
              id: resq[i].id,
              imeicode: resq[i].imeicode,
              spo2_val: resq[i].spo2_val,
              time_log: resq[i].time_log,
            });
          }
        }

        res.sort(function (a: any, b: any) {
          return +new Date(a.time_log) - +new Date(b.time_log);
        });

        this.dataSpO2All = await res;
         // console.log("dataSpO2All -------------> ", this.dataSpO2All);

         if (!this.value) {
          this.filterDate("", "");
        } else {
          let sentStartDate: string = new Date(new Date(this.value).getTime() - (3600000 * 24)).toISOString()
          let sentEndDate: string = new Date(new Date(this.value).getTime()).toISOString()
          // var sentStartDate: any = this.datePipe.transform(this.value, "yyyy-MM-dd")?.toString();
          // var addOneDate = new Date(this.value);
          // addOneDate.setDate(addOneDate.getDate() + 1);
          // var sentEndDate: any = this.datePipe.transform(addOneDate, "yyyy-MM-dd")?.toString();
          this.filterDate(sentStartDate, sentEndDate);
          this.rangeDateStartAndEnd.setValue({
            start: sentStartDate,
            end: sentEndDate
          });
        }
      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
      }
    );
  }

  loadAvgHr(imeiCode: string) {
    // console.log('imeiCode :', imeiCode);

    return new Promise((resolve, reject) => {
      let API_URL = `${this.TrickerServer}/avg_log?sw_imei=${imeiCode}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: 'json' })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  async filterDate(dateStart: string, dateEnd: string) {
    // console.log(dateStart, " - ", dateEnd);
    var dataSpO2All_ = this.dataSpO2All;
    var dataSpO2: any;

    this.filterEmtyData = [];
    this.datetimeShowList = [];

    this.dataCalculateSpo2 = [];
    this.dataChartSpo2 = [];
    this.dataChart24List = [];
    this.dataChart7DayList = [];
    this.minSpo2Value = 0;
    this.maxSpo2Value = 0;
    this.averSpo2Value = 0;
    this.countMin = 0;
    this.countMax = 0;
    this.countval8089 = 0;

    if (dateStart != '' && dateEnd != '') {
      dataSpO2 = dataSpO2All_.filter((item: any) => {
        return (
          new Date(item.time_log).valueOf() >= new Date(dateStart).setHours(7, 0, 0, 0).valueOf() &&
          new Date(item.time_log).valueOf() <= new Date(dateEnd).valueOf()
        );
      });
    } else {
      //กรอง เฉพาะวันนี้ วันปัจจุบัน
      dataSpO2 = dataSpO2All_.filter((item: any) => {
        var timelog = new Date(item.time_log).valueOf();
        var timeNow = new Date().setHours(new Date().getHours()+7);
        var timeStart =new Date().setHours(7, 0, 0, 0).valueOf();
        return (
          timelog >= timeStart &&
          timelog <= timeNow
        );
      });
    }

    for (let i = 0; i < dataSpO2All_.length; i++) {
      this.dataCalculateSpo2.push({
        date: new Date(dataSpO2All_[i].time_log),
        time_h: new Date(
          formatDate(dataSpO2All_[i].time_log, 'yyyy/MM/dd HH:MM', 'en-US')
        ).getHours(),
        time_day: new Date(
          formatDate(dataSpO2All_[i].time_log, 'yyyy/MM/dd HH:MM', 'en-US')
        ).getDay(),
        value1: Number(dataSpO2All_[i].spo2_val),
      });
    }
    this.dataCalculateSpo2 = this.dataCalculateSpo2.map((item: any) => ({
      ...item,
      date: new Date(new Date(item.date).getTime() - (1000 * 60 * 60 * 7)),
      time_h: item.time_h - 7
    }));

    for (let i = 0; i < dataSpO2.length; i++) {
      this.dataChartSpo2.push({
        date: dataSpO2[i].time_log.split("T")[0]+" "+dataSpO2[i].time_log.split("T")[1].substring(0,5),
        dateformat: new Date(
          formatDate(dataSpO2[i].time_log, 'yyyy/MM/dd', 'en-US')
        ).toDateString(),
        value1: Number(dataSpO2[i].spo2_val),
      });
    }

    this.dataChartSpo2.sort(function (a: any, b: any) {
      return +new Date(a.date) - +new Date(b.date);
    });


    //SpO2 24Hr Chart
    var dataChartSpo2_: any = [];
    for (let i = 0; i < this.dataSpO2All.length; i++) {
      dataChartSpo2_.push({
        date: formatDate(
          this.dataSpO2All[i].time_log,
          'yyyy/MM/dd hh:mm',
          'en-US'
        ),
        dateformat: new Date(
          formatDate(this.dataSpO2All[i].time_log, 'yyyy/MM/dd', 'en-US')
        ).toLocaleDateString('en-US'),
        value1: Number(this.dataSpO2All[i].spo2_val),
      });
    }

    for (var i = 0; i < dataChartSpo2_.length; i++) {
      if (dataChartSpo2_[i].value1 != 0 && dataChartSpo2_[i].value1 != NaN) {
        this.filterEmtyData.push({
          dateformat: dataChartSpo2_[i].dateformat,
        });
      }
    }

    // Group DateTime For เลือกแบบวัน
    let group = this.filterEmtyData.reduce((r, a) => {
      r[a.dateformat] = [...(r[a.dateformat] || []), a];
      return r;
    }, {});
    const result_group = Object.keys(group).map(function (k) {
      const item = group[k];
      return {
        datetxtShow: item[0].dateformat,
      };
    });

    this.datetimeShowList = result_group;


    // console.log("this.dataCalculateSpo2 ",this.dataCalculateSpo2);
    // console.log("this.dataChartSpo2 ",this.dataChartSpo2);

    // ---------------------------------------- หา min max หาเฉลี่ย

    if(this.dataChartSpo2.length > 0){
    this.minSpo2Value = Math.min.apply(
      Math,
      this.dataChartSpo2.map(function (o) {
        return o.value1;
      })
    );
    this.maxSpo2Value = Math.max.apply(
      Math,
      this.dataChartSpo2.map(function (o) {
        return o.value1;
      })
    );
    var total = 0;
    for (var i = 0; i < this.dataChartSpo2.length; i++) {
      total += this.dataChartSpo2[i].value1;
    }
    this.averSpo2Value = total / this.dataChartSpo2.length;
  }

    // // หา 24 ชม. เฉลี่ย --------------------------- เอาตัวเลข time_h มา Group
    // const reduced = this.dataCalculateSpo2.reduce(function (m, d) {
    //   if (!m[d.time_h]) {
    //     m[d.time_h] = { ...d, count: 1 };
    //     return m;
    //   }
    //   m[d.time_h].value1 += d.value1;
    //   m[d.time_h].count += 1;
    //   return m;
    // }, {});

    // // หาค่าเฉลี่ย
    // const result = Object.keys(reduced).map(function (k) {
    //   const item = reduced[k];
    //   return {
    //     time_h: item.time_h,
    //     // time_h: ("00" + item.time_h).slice(-2) + ":00 น",
    //     avg: item.value1 / item.count,
    //   }
    // });

    // // โชว์ค่าทั้ง 24 ชั่วโมง
    // for (var i = 0; i < 24; i++) {
    //   var index = result.findIndex(e => e.time_h == i);
    //   if (index > -1) {
    //     this.dataChart24List.push({
    //       time_h: ("00" + result[index].time_h).slice(-2) + ":00 น",
    //       avg: result[index].avg,
    //       num_hr: result[index].time_h
    //     });
    //   } else {
    //     this.dataChart24List.push({
    //       time_h: ("00" + i).slice(-2) + ":00 น",
    //       avg: 0,
    //       num_hr: i
    //     });
    //   }
    // }

    // await this.loadAvgHr(this.gimeiCode).then((resAvg: any) => {
    //   if(resAvg != null){
    //   this.dataChart24List = resAvg.avg_Spo2;

    //   this.dataChart24List_new = [];

    //   for (let index = 0; index < this.dataChart24List.length; index++) {
    //     // const element = this.dataChart24List[index].num_hr;
    //     var now = new Date();
    //     now.setHours(this.dataChart24List[index].num_hr);
    //     now.setMinutes(0);

    //     this.dataChart24List_new.push({
    //       time_h: now,
    //       avg: this.dataChart24List[index].avg,
    //     });
    //   }
    //   // console.log('this.dataChart24ListAA', this.dataChart24List);
    //   // console.log('this.dataChart24List_new', this.dataChart24List_new);

    //   // console.log(resq.avg_Spo2);

    //   this.GraphAver24hLine(this.dataChart24List);
    // }
    // });

    // this.dataChart24List = result;

    // console.log("dataChart24List AveAll", this.dataChart24List);




    // --------------------------------- หาผล min กี่ครั้ง , max กี่ครั้ง
    if(this.dataChartSpo2.length > 0){
    for (var i = 0; i < this.dataChartSpo2.length; i++) {
      if (this.dataChartSpo2[i].value1 <= 79) {
        this.countMin++;
      }
      else if (this.dataChartSpo2[i].value1 >= 90) {
        this.countMax++;
      }
      else if (this.dataChartSpo2[i].value1 >= 80 && this.dataChartSpo2[i].value1 <= 89) {
        this.countval8089++;
      }
      else {
        this.countvalErr++;
      }
    }
  }




    // หา 7 วันเฉลี่ย --------------------- เอาตัวเลข time_day มา Group
    const reduced_day = this.dataCalculateSpo2.reduce(function (m, d) {
      if (!m[d.time_day]) {
        m[d.time_day] = { ...d, count: 1 };
        return m;
      }
      m[d.time_day].value1 += d.value1;
      m[d.time_day].count += 1;
      return m;
    }, {});




    // หาค่าเฉลี่ย
    const result_day = Object.keys(reduced_day).map(function (k) {
      const item = reduced_day[k];

      var daynames = '';

      switch (item.time_day) {
        case 0:
          daynames = 'วันอาทิตย์';
          break;
        case 1:
          daynames = 'วันจันทร์';
          break;
        case 2:
          daynames = 'วันอังคาร';
          break;
        case 3:
          daynames = 'วันพุธ';
          break;
        case 4:
          daynames = 'วันพฤหัส';
          break;
        case 5:
          daynames = 'วันศุกร์';
          break;
        default:
          daynames = 'วันเสาร์';
          break;
      }

      return {
        time_day: daynames,
        avg: item.value1 / item.count,
      };
    });




    this.dataChart7DayList = result_day;

    // GEN กราฟ
    this.GraphSpo2Demo();
    this.GraphSpo2CollDemo();
    this.GraphAver7days();
    this.GraphValPercen();

  }






  loadChartbyDateFormAPI(imeiCode: string, datetimes: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.ipServer}/api/smartwatch_cn_api/getCN_DaySpo2?imeicode=${imeiCode}&date=${datetimes}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: 'json' })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  async loadDatabyDateChart(imeiCode: string, datetimes: string) {
    await this.loadChartbyDateFormAPI(imeiCode, datetimes).then(
      (res: any) => {
        this.dataChart24List_Day1 = [];
        this.dataCalculateSpo2_Day1 = [];

        for (let i = 0; i < res.length; i++) {
          this.dataCalculateSpo2_Day1.push({
            date: new Date(res[i].time_log),
            time_h: new Date(res[i].time_log),

            // time_h: new Date(formatDate(res[i].time_log, 'yyyy/MM/dd HH:MM', 'en-US')).getHours(),
            time_day: new Date(
              formatDate(res[i].time_log, 'yyyy/MM/dd HH:MM', 'en-US')
            ).getDay(),
            value1: Number(res[i].spo2_val),
            avg: Number(res[i].spo2_val),
          });
        }

        const reduced = this.dataCalculateSpo2_Day1.reduce(function (m, d) {
          if (!m[d.time_h]) {
            m[d.time_h] = { ...d, count: 1 };
            return m;
          }
          m[d.time_h].value1 += d.value1;
          m[d.time_h].count += 1;
          return m;
        }, {});

        const result = Object.keys(reduced).map(function (k) {
          const item = reduced[k];
          return {
            // time_h: ("00" + item.time_h).slice(-2) + ":00 น",
            time_h: item.time_h,
            avg: item.value1 / item.count,
          };
        });

        this.dataChart24List_Day1 = this.dataCalculateSpo2_Day1;
        this.dataChart24List_Day1 = this.dataChart24List_Day1.map((item: any) => ({
          ...item,
          date: new Date(new Date(item.date).getTime() - (1000 * 60 * 60 * 7)),
          time_h: new Date(new Date(item.time_h).getTime() - (1000 * 60 * 60 * 7))
        }));

        // // โชว์ค่าทั้ง 24 ชั่วโมง
        // for (var i = 0; i < 24; i++) {
        //   var index = result.findIndex(e => e.time_h == i);
        //   if (index > -1) {
        //     this.dataChart24List_Day1.push({
        //       time_h: ("00" + result[index].time_h).slice(-2) + ":00 น",
        //       avg: result[index].avg,
        //       num_hr: result[index].time_h
        //     });
        //   } else {
        //     this.dataChart24List_Day1.push({
        //       time_h: ("00" + i).slice(-2) + ":00 น",
        //       avg: 0,
        //       num_hr: i
        //     });
        //   }
        // }

        for (let index = 0; index < this.dataChart24List_new.length; index++) {
          // this.dataChart24List_new[index].time_h.setDate(18);
          var split_date = datetimes.split('-');
          this.dataChart24List_new[index].time_h.setFullYear(
            split_date[0],
            Number(split_date[1]) - 1,
            split_date[2]
          );
        }

        // this.GraphAver24h(this.dataChart24List_Day1);

        // console.log("this.dataChart24List_Day1 ", this.dataChart24List_Day1);

        this.GraphAver24hLine(this.dataChart24List_Day1);
      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
      }
    );
  }

  GraphSpo2Demo() {

    this.changeDetectRef.detectChanges();
    am4core.useTheme(am4themes_animated);

    // Create chart instance
    var chart = am4core.create(this.chartSpo2.nativeElement, am4charts.XYChart);
    chart.exporting.menu = new am4core.ExportMenu();
    this.dataChartSpo2 = this.dataChartSpo2.map((item: any) => ({
      ...item,
      date: this.datePipe.transform(item.date, "dd-MM-yyyy H:mm")
    }));
    chart.data = this.dataChartSpo2;

    let gradient = new am4core.LinearGradient();
    gradient.addColor(am4core.color('#243b55'));
    gradient.addColor(am4core.color('#141e30'));
    gradient.rotation = 90;
    chart.background.fill = gradient;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'date';
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.rotation = 300;
    categoryAxis.renderer.grid.template.stroke = am4core.color('#ffffff');
    categoryAxis.renderer.labels.template.fill = am4core.color('#ffffff');

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 70;
    valueAxis.max = 100;
    valueAxis.renderer.grid.template.stroke = am4core.color('#ffffff');
    valueAxis.renderer.labels.template.fill = am4core.color('#ffffff');

    // Create series
    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = 'value1';
    series.dataFields.categoryX = 'date';
    series.strokeWidth = 1.2;
    series.minBulletDistance = 10;
    series.stroke = am4core.color('#64dbfb');
    series.tooltipText = '[bold]{date}:[/] {value1}\n[bold]';

    // chart.scrollbarX = new am4charts.XYChartScrollbar();
    // chart.scrollbarX.series.push(series);

    var rangeareatop = valueAxis.axisRanges.create();
    rangeareatop.value = 100;
    rangeareatop.endValue = 90;
    rangeareatop.axisFill.fill = am4core.color('#00ba00');
    rangeareatop.axisFill.fillOpacity = 0.2;
    rangeareatop.grid.strokeOpacity = 0;

    var rangeareabottom = valueAxis.axisRanges.create();
    rangeareabottom.value = 79;
    rangeareabottom.endValue = 90;
    rangeareabottom.axisFill.fill = am4core.color('#f8ff00');
    rangeareabottom.axisFill.fillOpacity = 0.2;
    rangeareabottom.grid.strokeOpacity = 0;

    var axisRangetop = valueAxis.axisRanges.create();
    axisRangetop.value = 79;
    axisRangetop.grid.strokeOpacity = 1;
    axisRangetop.grid.stroke = am4core.color('#f3260e');
    axisRangetop.grid.strokeWidth = 1;


    // var labelbaselinetop = chart.createChild(am4core.Label);
    // labelbaselinetop.text = 'Good SpO2 Base Line (95-100)';
    // labelbaselinetop.fontSize = 16;
    // labelbaselinetop.isMeasured = false;
    // labelbaselinetop.x = am4core.percent(80);
    // labelbaselinetop.y = 30;

    // var labelbaselinebottom = chart.createChild(am4core.Label);
    // labelbaselinebottom.text = 'Bad SpO2 Base Line (Lower 90)';
    // labelbaselinebottom.fontSize = 16;
    // labelbaselinebottom.isMeasured = false;
    // labelbaselinebottom.x = am4core.percent(80);
    // labelbaselinebottom.y = 380;

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = categoryAxis;
  }



  GraphSpo2CollDemo() {

    var newColor = [];

    am4core.useTheme(am4themes_animated);

    var chart = am4core.create(
      this.chartSpo2Coll.nativeElement,
      am4charts.XYChart
    );
    chart.exporting.menu = new am4core.ExportMenu();

    let gradient = new am4core.LinearGradient();
    gradient.addColor(am4core.color('#243b55'));
    gradient.addColor(am4core.color('#141e30'));
    gradient.rotation = 90;
    chart.background.fill = gradient;

    for (var i = 0; i < this.dataChartSpo2.length; i++) {
      if (this.dataChartSpo2[i].value1 >= 90) {
        newColor.push({
          date: this.dataChartSpo2[i].date,
          dateformat: this.dataChartSpo2[i].dateformat,
          value1: this.dataChartSpo2[i].value1,
          color: am4core.color('#00ba00'),
        });
      }
      else if (this.dataChartSpo2[i].value1 >= 80 && this.dataChartSpo2[i].value1 <= 89) {
        newColor.push({
          date: this.dataChartSpo2[i].date,
          dateformat: this.dataChartSpo2[i].dateformat,
          value1: this.dataChartSpo2[i].value1,
          color: am4core.color('#ffab00'),
        });
      }
      else {
        newColor.push({
          date: this.dataChartSpo2[i].date,
          dateformat: this.dataChartSpo2[i].dateformat,
          value1: this.dataChartSpo2[i].value1,
          color: am4core.color('#ff0000'),
        });
      }
    }

    chart.data = newColor;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'date';
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.rotation = 300;
    categoryAxis.renderer.grid.template.stroke = am4core.color('#ffffff');
    categoryAxis.renderer.labels.template.fill = am4core.color('#ffffff');

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.max = 100;
    valueAxis.min = 80;
    valueAxis.renderer.grid.template.stroke = am4core.color('#ffffff');
    valueAxis.renderer.labels.template.fill = am4core.color('#ffffff');

    var series = chart.series.push(new am4charts.ColumnSeries());
    series.sequencedInterpolation = true;
    series.dataFields.valueY = 'value1';
    series.dataFields.categoryX = 'date';
    series.tooltipText = '[bold]{date}:[/] {value1}\n[bold]';
    series.columns.template.strokeWidth = 0;
    series.columns.template.column.cornerRadiusTopLeft = 10;
    series.columns.template.column.cornerRadiusTopRight = 10;
    series.columns.template.column.fillOpacity = 0.8;
    series.columns.template.propertyFields.fill = 'color';
    series.tooltipPosition = 'pointer';

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = categoryAxis;
  }

  GraphAver24hLine(mainData: any[]) {
    setTimeout(() => {
      am4core.useTheme(am4themes_animated);
  
      var chart = am4core.create(
        this.chartSpo2Aver24Line.nativeElement,
        am4charts.XYChart
      );
      chart.exporting.menu = new am4core.ExportMenu();
  
      let gradient = new am4core.LinearGradient();
      gradient.addColor(am4core.color('#243b55'));
      gradient.addColor(am4core.color('#141e30'));
      gradient.rotation = 90;
      chart.background.fill = gradient;
  
      let newList: any = [];
  
      for (var i = 0; i < mainData.length; i++) {
        newList.push({
          time_h: mainData[i].time_h,
          avg: mainData[i].avg,
          num_hr: i,
        });
      }
  
      chart.data = newList;
  
      // let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      // categoryAxis.dataFields.category = "time_h";
      // categoryAxis.renderer.grid.template.location = 0;
      // categoryAxis.renderer.minGridDistance = 30;
      // categoryAxis.renderer.labels.template.horizontalCenter = "right";
      // categoryAxis.renderer.labels.template.verticalCenter = "middle";
      // categoryAxis.renderer.labels.template.rotation = 270;
      // categoryAxis.renderer.minHeight = 110;
      // categoryAxis.renderer.grid.template.stroke = am4core.color("#ffffff");
      // categoryAxis.renderer.labels.template.fill = am4core.color("#ffffff");
  
      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.baseInterval = {
        timeUnit: 'minute',
        count: 1,
      };
      dateAxis.renderer.grid.template.stroke = am4core.color('#ffffff');
      dateAxis.renderer.labels.template.fill = am4core.color('#ffffff');
  
      let dateAxis2 = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis2.renderer.grid.template.location = 0;
      dateAxis2.renderer.minGridDistance = 40;
      dateAxis2.renderer.labels.template.disabled = true;
      dateAxis2.renderer.grid.template.disabled = true;
      dateAxis2.cursorTooltipEnabled = false;
  
      let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
      // valueAxis2.renderer.opposite = true;
      // valueAxis2.renderer.grid.template.disabled = true;
      // valueAxis2.renderer.labels.template.disabled = true;
      valueAxis2.renderer.grid.template.stroke = am4core.color('#ffffff');
      valueAxis2.renderer.labels.template.fill = am4core.color('#ffffff');
      valueAxis2.min = 90;
      valueAxis2.max = 100;
  
  
      var rangeareatop = valueAxis2.axisRanges.create();
      rangeareatop.value = 100;
      rangeareatop.endValue = 90;
      rangeareatop.axisFill.fill = am4core.color('#00ba00');
      rangeareatop.axisFill.fillOpacity = 0.2;
      rangeareatop.grid.strokeOpacity = 0;
  
      var rangeareabottom = valueAxis2.axisRanges.create();
      rangeareabottom.value = 79;
      rangeareabottom.endValue = 90;
      rangeareabottom.axisFill.fill = am4core.color('#f8ff00');
      rangeareabottom.axisFill.fillOpacity = 0.2;
      rangeareabottom.grid.strokeOpacity = 0;
  
      var axisRangetop = valueAxis2.axisRanges.create();
      axisRangetop.value = 79;
      axisRangetop.grid.strokeOpacity = 1;
      axisRangetop.grid.stroke = am4core.color('#f3260e');
      axisRangetop.grid.strokeWidth = 1;
  
  
  
      let series2 = chart.series.push(new am4charts.LineSeries());
      series2.dataFields.valueY = 'avg';
      series2.dataFields.dateX = 'time_h';
      series2.data = this.dataChart24List_new;
      series2.xAxis = dateAxis;
      series2.yAxis = valueAxis2;
      series2.strokeWidth = 3;
      series2.tooltipText = "Average : [bold]{valueY.formatNumber('#.00')}[/]%";
      series2.stroke = am4core.color('#cd0506');
      series2.fill = am4core.color('#cd0506');
  
      let series1 = chart.series.push(new am4charts.LineSeries());
      series1.dataFields.valueY = 'avg';
      series1.dataFields.dateX = 'time_h';
      series1.data = newList.reverse();
      series1.xAxis = dateAxis;
      series1.yAxis = valueAxis2;
      series1.strokeWidth = 2;
      series1.tooltipText = "ปัจจุบัน [bold]{valueY.formatNumber('#.00')}[/]%";
      series1.stroke = am4core.color('#00bce6');
      series1.fill = am4core.color('#00bce6');
  
      // Add cursor
      chart.cursor = new am4charts.XYCursor();
    })
  }

  async loadDatabyDateChartAndReturn(datetimes: string) {
    var objList: any = [];

    await this.loadChartbyDateFormAPI(this.gimeiCode, datetimes).then(
      (res: any) => {
        var List_Day1: any = [];
        var Spo2_Day1: any = [];

        for (let i = 0; i < res.length; i++) {
          Spo2_Day1.push({
            date: new Date(res[i].time_log),
            time_h: new Date(
              formatDate(res[i].time_log, 'yyyy/MM/dd HH:MM', 'en-US')
            ).getHours(),
            time_day: new Date(
              formatDate(res[i].time_log, 'yyyy/MM/dd HH:MM', 'en-US')
            ).getDay(),
            value1: Number(res[i].spo2_val),
          });
        }

        const reduced = Spo2_Day1.reduce(function (m: any, d: any) {
          if (!m[d.time_h]) {
            m[d.time_h] = { ...d, count: 1 };
            return m;
          }
          m[d.time_h].value1 += d.value1;
          m[d.time_h].count += 1;
          return m;
        }, {});

        const result = Object.keys(reduced).map(function (k) {
          const item = reduced[k];
          return {
            time_h: item.time_h,
            avg: item.value1 / item.count,
          };
        });

        // โชว์ค่าทั้ง 24 ชั่วโมง
        for (var i = 0; i < 24; i++) {
          var index = result.findIndex((e) => e.time_h == i);
          if (index > -1) {
            List_Day1.push({
              time_h: ('00' + result[index].time_h).slice(-2) + ':00 น',
              avg: result[index].avg,
              num_hr: result[index].time_h,
            });
          } else {
            List_Day1.push({
              time_h: ('00' + i).slice(-2) + ':00 น',
              avg: 0,
              num_hr: i,
            });
          }
        }

        objList.push({
          time_day: datetimes,
          data_list: List_Day1,
        });
      },
      (error) => {
        // console.log(error.status + ' ' + error.statusText);
      }
    );

    return objList;
  }

  GraphAver7days() {
    am4core.useTheme(am4themes_animated);
    // Themes end

    var chart = am4core.create(
      this.chartSpo2Aver7day.nativeElement,
      am4charts.XYChart
    );
    chart.exporting.menu = new am4core.ExportMenu();

    // Add data
    chart.data = this.dataChart7DayList;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'time_day';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.horizontalCenter = 'right';
    categoryAxis.renderer.labels.template.verticalCenter = 'middle';
    categoryAxis.renderer.labels.template.rotation = 270;
    categoryAxis.renderer.minHeight = 110;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.minWidth = 50;

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.sequencedInterpolation = true;
    series.dataFields.valueY = 'avg';
    series.dataFields.categoryX = 'time_day';
    series.tooltipText = "[{categoryX}: bold]{valueY.formatNumber('#.00')}[/]";
    series.columns.template.strokeWidth = 0;
    series.columns.template.column.cornerRadiusTopLeft = 10;
    series.columns.template.column.cornerRadiusTopRight = 10;
    series.columns.template.column.fillOpacity = 0.8;
    series.fill = am4core.color('#514ab8');

    // on hover, make corner radiuses bigger
    let hoverState = series.columns.template.column.states.create('hover');
    hoverState.properties.cornerRadiusTopLeft = 0;
    hoverState.properties.cornerRadiusTopRight = 0;
    hoverState.properties.fillOpacity = 1;

    // Cursor
    chart.cursor = new am4charts.XYCursor();
  }

  GraphValPercen() {
    var valmin = (this.countMin / this.dataChartSpo2.length) * 100;
    var val8089 = (this.countval8089 / this.dataChartSpo2.length) * 100;
    var valmax = (this.countMax / this.dataChartSpo2.length) * 100;

    am4core.useTheme(am4themes_animated);
    // Themes end

    var chart = am4core.create(
      this.chartSpo2ValPercen.nativeElement,
      am4charts.XYChart
    );
    chart.exporting.menu = new am4core.ExportMenu();

    chart.data = [
      {
        country: 'Spo2 อยู่ในช่วง 90-100%',
        visits: valmax.toFixed(2),
        color: chart.colors.next(),
      },
      {
        country: '80-89%',
        visits: val8089.toFixed(2),
        color: chart.colors.next(),
      },
      {
        country: 'ต่ำกว่าหรือเท่ากับ 79%',
        visits: valmin.toFixed(2),
        color: chart.colors.next(),
      },
    ];

    //create category axis for years
    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'country';
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.location = 0;

    //create value axis for income and expenses
    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.opposite = true;

    //create columns
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryY = 'country';
    series.dataFields.valueX = 'visits';
    series.name = 'Spo2';
    series.columns.template.fillOpacity = 0.5;
    series.columns.template.strokeOpacity = 0;
    series.tooltipText = "{categoryY} : {valueX.value.formatNumber('#.00')}%";
    series.columns.template.propertyFields.fill = 'color';

    //add chart cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = 'zoomY';

    //add legend
    chart.legend = new am4charts.Legend();
  }

  onChangeDateTimeEvent(event: any, isFirst: boolean) {
    // console.log("event ",event);
    // console.log("event.value ",event.value);
    if (!isFirst) var datetimeStr = formatDate(event.value, 'yyyy-MM-dd', 'en-US').toString();
    else var datetimeStr = formatDate(event, 'yyyy-MM-dd', 'en-US').toString();
    // console.log("onChangeDateTimeEvent ", datetimeStr);
    this.loadDatabyDateChart(this.gimeiCode, datetimeStr);
    this.loadDataTickerLogToTable(this.gimeiCode, datetimeStr);
  }
}
