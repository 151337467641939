<div class="ai-modal">
    <div class="ai-modal-box">
        <div class="amb-header bg-info">
            <h1>AI Charts ({{imei}})</h1>
            <i (click)="closeModal()" class="fas fa-times text-danger"></i>
        </div>
        <div class="amb-body">
            <div *ngIf="charts && !loading">
                <h1 class="font-weight-bold text-center">การเปรียบเทียบค่าเฉลี่ยและค่าล่าสุด พร้อมแสดงการเปลี่ยนแปลง (%)</h1>
                <apx-chart *ngIf="chartZero"
                    [series]="chartZero.series"
                    [chart]="chartZero.chart"
                    [colors]="chartZero.colors"
                    [title]="chartZero.title"
                    [xaxis]="chartZero.xaxis"
                    [yaxis]="chartZero.yaxis"
                    [stroke]="chartZero.stroke"
                    [legend]="chartZero.legend"
                    [tooltip]="chartZero.tooltip"
                ></apx-chart>
                <div class="ai-row">
                    <div class="ai-item">
                        <h2 class="mb-3">แนวโน้มการฟื้นตัว (Recovery Chart)</h2>
                        <p class="mb-3">อัตราการเต้นของหัวใจและค่าความเครียดในช่วงเวลาต่าง ๆ</p>
                        <apx-chart *ngIf="chartI"
                            [series]="chartI.series"
                            [chart]="chartI.chart"
                            [xaxis]="chartI.xaxis"
                            [yaxis]="chartI.yaxis"
                            [stroke]="chartI.stroke"
                            [legend]="chartI.legend"
                            [tooltip]="chartI.tooltip"
                        ></apx-chart>
                    </div>
                    <div class="ai-item">
                        <h2 class="mb-3">ความถี่ของค่าผิดปกติ (Anomaly Frequency)</h2>
                        <p class="mb-3">จำนวนครั้งที่เกิดความผิดปกติในรูปแบบต่าง ๆ เช่น ค่าที่เกินขอบเขต หรือค่าที่มีการเปลี่ยนแปลงแบบค่อยเป็นค่อยไป</p>
                        <apx-chart *ngIf="chartII"
                            [series]="chartII.series"
                            [chart]="chartII.chart"
                            [xaxis]="chartII.xaxis"
                            [yaxis]="chartII.yaxis"
                            [stroke]="chartII.stroke"
                            [legend]="chartII.legend"
                            [tooltip]="chartII.tooltip"
                        ></apx-chart>
                    </div>
                    <div class="ai-item">
                        <h2 class="mb-3">แผนภาพกระจาย: ความเครียดเทียบกับอัตราการเต้นของหัวใจ (Scatter Plot: Stress vs Heart Rate)</h2>
                        <p class="mb-3">เปรียบเทียบความสัมพันธ์ระหว่างค่าความเครียดและอัตราการเต้นของหัวใจ</p>
                        <apx-chart *ngIf="chartIV"
                            [series]="chartIV.series"
                            [chart]="chartIV.chart"
                            [colors]="chartIV.colors"
                            [xaxis]="chartIV.xaxis"
                            [yaxis]="chartIV.yaxis"
                            [stroke]="chartIV.stroke"
                            [legend]="chartIV.legend"
                            [tooltip]="chartIV.tooltip"
                        ></apx-chart>
                    </div>
                    <div class="ai-item">
                        <h2 class="mb-3">แนวโน้มค่าความผิดปกติ (Anomaly Score)</h2>
                        <p class="mb-3">ค่าความผิดปกติที่ถูกคำนวณในช่วงเวลาต่าง ๆ เพื่อติดตามการเปลี่ยนแปลง</p>
                        <apx-chart *ngIf="chartIII"
                            [series]="chartIII.series"
                            [chart]="chartIII.chart"
                            [xaxis]="chartIII.xaxis"
                            [yaxis]="chartIII.yaxis"
                            [stroke]="chartIII.stroke"
                            [legend]="chartIII.legend"
                            [tooltip]="chartIII.tooltip"
                        ></apx-chart>
                    </div>
                </div>
            </div>
            <div *ngIf="!charts && !loading">
                <h2 class="text-center font-italic m-0">[ No chart data. ]</h2>
            </div>
            <div *ngIf="loading" class="d-flex justify-content-center">
                <div class="spinner-border text-info" style="zoom: 2;"></div>
            </div>
        </div>
    </div>
</div>