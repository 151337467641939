import { ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef, Component, ElementRef, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { formatDate, DatePipe } from '@angular/common';
import { SharedServiceService } from 'src/app/common/shared-service.service';
import { FormControl, FormGroup } from '@angular/forms';
import { GlobalvalDialog } from '../modal/globalval/globalval.component';
import { MatDialog } from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
  selector: 'app-heart-rate',
  templateUrl: './heart-rate.component.html',
  styleUrls: ['./heart-rate.component.scss']
})
export class HeartRateComponent implements OnInit {

  httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  ipServer: string = GlobalConstants.imageUrl2;
  TrickerServer: string = GlobalConstants.unknownUrl;

  pageGetDatatype: string = 'heartrate';


  // ipServer: string = "https://localhost:44378";

  dataChartHeartRate: any[] = [];
  dataCalculateHeartRate: any[] = [];
  dataChart24List: any[] = [];
  dataChart24List_new: any[] = [];

  dataChart7DayList: any[] = [];

  dataChart24List_Day1: any[] = [];
  dataCalculateHeartRate_Day1: any[] = [];


  filterEmtyData: any[] = [];
  datetimeShowList: any[] = [];


  minHeartRateValue: any = 0;
  maxHeartRateValue: any = 0;
  averHeartRateValue: any = 0;
  tickerDataToTableList: any[] = [];


  countMin: any = 0;
  countMax: any = 0;
  countNormal: any = 0;

  gimeiCode: any = "";
  dataHeartRateAll:any = [];

  rangeDateStartAndEnd = new FormGroup({
    start: new FormControl(new Date()),
    end: new FormControl(new Date())
  });

  imei: string = "";
  value: string = "";
  loading: boolean = true;
  setNow: any = new Date();

  @ViewChild('chartHeartRate', { static: false }) chartHeartRate!: ElementRef;
  // @ViewChild('chartHeartRateAver24', { static: false }) chartHeartRateAver24!: ElementRef;
  @ViewChild('chartHeartRateAver24Line', { static: false }) chartHeartRateAver24Line!: ElementRef;
  @ViewChild('chartHeartRateAver7day', { static: false }) chartHeartRateAver7day!: ElementRef;
  @ViewChild('chartHeartRateValPercen', { static: false }) chartHeartRateValPercen!: ElementRef;

  constructor(private httpClient: HttpClient,
    private sharedServiceService: SharedServiceService,
    @Inject(LOCALE_ID) private locale: string,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private changeDetectRef:ChangeDetectorRef,
    private route: ActivatedRoute,
    private datePipe: DatePipe
  ) {
    this.imei = this.route.snapshot.params["imei"];
    this.value = this.route.snapshot.params["value"];
  }

  ngOnInit(): void {
    this.sharedServiceService.deviceIDValue.subscribe(
      (message) => {
        // console.log("message ID ", message);
        this.loadDataChart(message ? message : this.imei);
      }
    );
    this.loadGobalListData();
    let dateNow = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    setTimeout(async () => {
      this.loading = false;
      await this.onChangeDateTimeEvent(dateNow, true);
    }, 1500);
  }

  onChengDate() {

    var dataStart: string = "";
    var dataEnd: string = "";

    dataStart = this.rangeDateStartAndEnd.value.start;
    dataEnd = this.rangeDateStartAndEnd.value.end;

    if (this.rangeDateStartAndEnd.value.start != null && this.rangeDateStartAndEnd.value.end != null) {
      var dataStart_ = formatDate(dataStart, 'yyyy-MM-dd', this.locale);
      var dataEnd_ = formatDate(dataEnd, 'yyyy-MM-dd', this.locale);

      var newEnd = new Date(dataEnd);
      var newEndAddDay = newEnd.setDate(newEnd.getDate() + 1);
      dataEnd_ = formatDate(newEndAddDay, 'yyyy-MM-dd', this.locale);
      // console.log("dataStart_ ", dataStart_, " | dataEnd_ ", dataEnd_);

      this.filterDate(dataStart_, dataEnd_);
    }

  }

  openDialogGlovbalval(typeGlobal: any): void {
    const dialogRef = this.dialog.open(GlobalvalDialog, {
      width: '700px',
      data: { typeGlobal },
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log('The dialog was closed', result);
      if ((result+"").startsWith('Update')) {
        this.loadGobalListData();
        this._snackBar.open(result, 'Close', {
          duration: 1000
        });
      }
      else{
        this._snackBar.open(result, 'Close', {
          duration: 3000
        });
      }
    });
  }

  g_name: any;
  g_minvalue: any = 0;
  g_maxvalue: any = 0;
  g_diffvalue: any = 0;

  loadGobalValFormAPI() {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.ipServer}/api/smartwatch_ticker/getEditGobalValue?gobaltext=${this.pageGetDatatype}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: 'json' })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  async loadGobalListData() {
    await this.loadGobalValFormAPI().then(
      (res: any) => {
        // console.log(res);

        this.g_name = res['swgvName'];
        this.g_minvalue = res['swgvMinValue'];
        this.g_maxvalue = res['swgvMaxValue'];
        this.g_diffvalue = res['swgvDifferenceValue'];
      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
      }
    );
  }

  setGlobalValue(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.ipServer}/api/smartwatch_ticker/setEditInfoValue`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: 'text',
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  saveGobalDataValue(minval: any, maxval: any, diffval: any) {
    // console.log('save ', minval, maxval, diffval);

    var jsonData = {
      SwgvName: 'heartrate',
      SwgvMinValue: minval,
      SwgvMaxValue: maxval,
      SwgvDifferenceValue: diffval,
    };

    this.setGlobalValue(jsonData).then(
       (res: any) => {
        this.loadGobalListData();
        // console.log(res.data);
      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
      }
    );
  }

  loadIMEIListFormAPI() {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.ipServer}/api/smartwatch_cn_api/getCN_HeartRate_CollectionList`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" }).subscribe(data => {
        resolve(data);
      },
        error => {
          reject(error);
        });
    });
  }


  loadChartFormAPI(imeiCode: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.ipServer}/api/heartRate/getLogHeartRateList?imeicode=${imeiCode}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" }).subscribe(data => {
        resolve(data);
      },
        error => {
          reject(error);
        });
    });
  }

  async loadDataChart(imeiCode: string) {
    await this.loadChartFormAPI(imeiCode).then(
      async (res: any) => {

        //  console.log("res -------------- ", res);
        this.gimeiCode = imeiCode;

        let conDataHr: any = [];

        for (let i = 0; i < res.length; i++) {
          conDataHr = conDataHr.concat(res[i].heart_rate);
        }


        conDataHr.sort(function (a: any, b: any) {
          return + new Date(a.time) - + new Date(b.time);
        });
        //  console.log("conDataHr ",conDataHr);

        this.dataHeartRateAll = await conDataHr;

        if (!this.value) {
          this.filterDate("", "");
        } else {
          let sentStartDate: string = new Date(new Date(this.value).getTime() - (3600000 * 24)).toISOString()
          let sentEndDate: string = new Date(new Date(this.value).getTime()).toISOString()
          // var sentStartDate: any = this.datePipe.transform(this.value, "yyyy-MM-dd")?.toString();
          // var addOneDate = new Date(this.value);
          // addOneDate.setDate(addOneDate.getDate() + 1);
          // var sentEndDate: any = this.datePipe.transform(addOneDate, "yyyy-MM-dd")?.toString();
          this.filterDate(sentStartDate, sentEndDate);
          this.rangeDateStartAndEnd.setValue({
            start: sentStartDate,
            end: sentEndDate
          });
        }


      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
      })
  }

  loadAvgHr(imeiCode: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.TrickerServer}/avg_log?sw_imei=${imeiCode}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: 'json' })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  async filterDate(dateStart: string, dateEnd: string) {
    var dataHeartRateAll_ = this.dataHeartRateAll;
    var dataHeartRate: any;
    this.filterEmtyData = [];
    this.datetimeShowList = [];

    this.dataCalculateHeartRate = [];
    this.dataChartHeartRate = [];
    this.dataChart24List = [];
    this.dataChart7DayList = [];
    this.minHeartRateValue = 0;
    this.maxHeartRateValue = 0;
    this.averHeartRateValue = 0;
    this.countMin = 0;
    this.countMax = 0;
    this.countNormal = 0;

    if (dateStart != "" && dateEnd != "") {
      dataHeartRate = dataHeartRateAll_.filter((item: any) => {
        return (new Date(item.time).valueOf() >= new Date(dateStart).setHours(7, 0, 0, 0).valueOf()
        && new Date(item.time).valueOf() <= new Date(dateEnd).valueOf());
      });
    } else {
      //กรอง เฉพาะวันนี้ วันปัจจุบัน
      dataHeartRate = dataHeartRateAll_.filter((item: any) => {
        var timelog = new Date(item.time).valueOf();
        var timeNow = new Date().setHours(new Date().getHours()+7);
        var timeStart =new Date().setHours(7, 0, 0, 0).valueOf();
        return (timelog >= timeStart &&
          timelog <= timeNow);
      });
    }

    for (let i = 0; i < dataHeartRateAll_.length; i++) {
      this.dataCalculateHeartRate.push({
        date: new Date(dataHeartRateAll_[i].time),
        time_h: new Date(formatDate(dataHeartRateAll_[i].time, 'yyyy/MM/dd HH:MM', 'en-US')).getHours(),
        time_day: new Date(formatDate(dataHeartRateAll_[i].time, 'yyyy/MM/dd HH:MM', 'en-US')).getDay(),
        value1: Number(dataHeartRateAll_[i].value),
      });
    }

    for (var i = 0; i < dataHeartRate.length; i++) {
      this.dataChartHeartRate.push({
        // date: formatDate(
        //   dataHeartRate[i].time,
        //   'yyyy/MM/dd hh:mm',
        //   'en-US'
        // ),
        date: dataHeartRate[i].time.split("T")[0]+" "+dataHeartRate[i].time.split("T")[1].substring(0,5),

        dateformat: new Date(formatDate(
          dataHeartRate[i].time,
          'yyyy/MM/dd',
          'en-US'
        )).toLocaleString().split(" ", 1)[0],
        value1: dataHeartRate[i].value,
      });
    }

    this.dataChartHeartRate.sort(function (a: any, b: any) {
      return + new Date(a.date) - + new Date(b.date);
    });

    var dataChartHeartRate_: any = []
    for (var i = 0; i < dataHeartRateAll_.length; i++) {
      dataChartHeartRate_.push({
        date: formatDate(
          dataHeartRateAll_[i].time,
          'yyyy/MM/dd hh:mm',
          'en-US'
        ),
        dateformat: new Date(formatDate(
          dataHeartRateAll_[i].time,
          'yyyy/MM/dd',
          'en-US'
        )).toLocaleString().split(" ", 1)[0],
        value1: dataHeartRateAll_[i].value,
      });
    }

    //  console.log("dataChartHeartRate ", this.dataChartHeartRate);
    // console.log("dataCalculateHeartRate ", this.dataCalculateHeartRate);

    for (var i = 0; i < dataChartHeartRate_.length; i++) {
      if (dataChartHeartRate_[i].value1 != 0 && dataChartHeartRate_[i].value1 != NaN) {
        this.filterEmtyData.push({
          dateformat: dataChartHeartRate_[i].dateformat
        });
      }
    }


    // for (var i = 0; i < this.dataChartHeartRate.length; i++) {
    //   if (this.dataChartHeartRate[i].value1 != 0 && this.dataChartHeartRate[i].value1 != NaN) {
    //     this.filterEmtyData.push({
    //       dateformat: this.dataChartHeartRate[i].dateformat
    //     });
    //   }
    // }

    let group = this.filterEmtyData.reduce((r, a) => {
      r[a.dateformat] = [...r[a.dateformat] || [], a];
      return r;
    }, {});
    // console.log("Group ", group);

    const result_group = Object.keys(group).map(function (k) {
      const item = group[k];
      return {
        datetxtShow: item[0].dateformat
      }
    });

    this.datetimeShowList = result_group;
    // console.log("Group datetimeShowList ", this.datetimeShowList);




    // ---------------------------------------- หา min max หาเฉลี่ย
    if(this.dataChartHeartRate.length > 0){
    this.minHeartRateValue = Math.min.apply(Math, this.dataChartHeartRate.map(function (o) { return o.value1; }));
    this.maxHeartRateValue = Math.max.apply(Math, this.dataChartHeartRate.map(function (o) { return o.value1; }));
    var total = 0;
    for (var i = 0; i < this.dataChartHeartRate.length; i++) {
      total += Number(this.dataChartHeartRate[i].value1);
    }
    this.averHeartRateValue = total / this.dataChartHeartRate.length;
  }



    // // หา 24 ชม. เฉลี่ย --------------------------- เอาตัวเลข time_h มา Group
    // const reduced = this.dataCalculateHeartRate.reduce(function (m, d) {
    //   if (!m[d.time_h]) {
    //     m[d.time_h] = { ...d, count: 1 };
    //     return m;
    //   }
    //   m[d.time_h].value1 += d.value1;
    //   m[d.time_h].count += 1;
    //   return m;
    // }, {});

    // // หาค่าเฉลี่ย
    // const result = Object.keys(reduced).map(function (k) {
    //   const item = reduced[k];
    //   return {
    //     time_h: item.time_h,
    //     // time_h: ("00" + item.time_h).slice(-2) + ":00 น",
    //     avg: item.value1 / item.count,
    //   }
    // });

    // // โชว์ค่าทั้ง 24 ชั่วโมง
    // for (var i = 0; i < 24; i++) {
    //   var index = result.findIndex(e => e.time_h == i);
    //   if (index > -1) {
    //     this.dataChart24List.push({
    //       time_h: ("00" + result[index].time_h).slice(-2) + ":00 น",
    //       avg: result[index].avg,
    //       num_hr: result[index].time_h
    //     });
    //   } else {
    //     this.dataChart24List.push({
    //       time_h: ("00" + i).slice(-2) + ":00 น",
    //       avg: 0,
    //       num_hr: i
    //     });
    //   }
    // }
    // console.log("dataChart24List AveAll", this.dataChart24List);

    // await this.loadAvgHr(this.gimeiCode).then((resAvg: any) => {
    //   // console.log("imecode :", this.gimeiCode);
    //   // console.log("loadAvgHr :", resAvg);

    //   if(resAvg != null){
    //   this.dataChart24List = resAvg.avg_Hr;
    //   this.dataChart24List_new = [];
    //   for (let index = 0; index < this.dataChart24List.length; index++) {
    //     // const element = this.dataChart24List[index].num_hr;
    //     var now = new Date();
    //     now.setHours(this.dataChart24List[index].num_hr);
    //     now.setMinutes(0);

    //     this.dataChart24List_new.push({
    //       time_h: now,
    //       avg: this.dataChart24List[index].avg,
    //     });
    //   }
    //   // console.log('this.dataChart24List', this.dataChart24List);
    //   // console.log('this.dataChart24List_new', this.dataChart24List_new);
    // }
    // });

    this.GraphAver24hLine(this.dataChart24List);


    // --------------------------------- หาผล min กี่ครั้ง , max กี่ครั้ง
    if(this.dataChartHeartRate.length > 0){
    for (var i = 0; i < this.dataChartHeartRate.length; i++) {
      if (this.dataChartHeartRate[i].value1 < 60) {
        this.countMin++;
      } else if (this.dataChartHeartRate[i].value1 > 100) {
        this.countMax++;
      } else {
        this.countNormal++;
      }
    }
  }



    // หา 7 วันเฉลี่ย --------------------- เอาตัวเลข time_day มา Group
    const reduced_day = this.dataCalculateHeartRate.reduce(function (m, d) {
      if (!m[d.time_day]) {
        m[d.time_day] = { ...d, count: 1 };
        return m;
      }
      m[d.time_day].value1 += d.value1;
      m[d.time_day].count += 1;
      return m;
    }, {});

    // หาค่าเฉลี่ย
    const result_day = Object.keys(reduced_day).map(function (k) {
      const item = reduced_day[k];

      var daynames = "";

      switch (item.time_day) {
        case 0:
          daynames = "วันอาทิตย์";
          break;
        case 1:
          daynames = "วันจันทร์";
          break;
        case 2:
          daynames = "วันอังคาร";
          break;
        case 3:
          daynames = "วันพุธ";
          break;
        case 4:
          daynames = "วันพฤหัส";
          break;
        case 5:
          daynames = "วันศุกร์";
          break;
        default:
          daynames = "วันเสาร์";
          break;
      }

      return {
        time_day: daynames,
        avg: item.value1 / item.count,
      }
    });

    this.dataChart7DayList = result_day;

    // GEN กราฟ
    this.GraphHeartRateDemo();
    // this.GraphAver24h(this.dataChart24List);
    // this.GraphAver24hLine(this.dataChart24List);
    this.GraphAver7days();
    this.GraphValPercen();
  }


  loadChartbyDateFormAPI(imeiCode: string, datetimes: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.ipServer}/api/heartRate/getDayHeartRate?imeicode=${imeiCode}&date=${datetimes}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" }).subscribe(data => {
        resolve(data);
      },
        error => {
          reject(error);
        });
    });
  }

  async loadDatabyDateChart(imeiCode: string, datetimes: string) {
    await this.loadChartbyDateFormAPI(imeiCode, datetimes).then(
      (res: any) => {

        this.dataChart24List_Day1 = [];
        this.dataCalculateHeartRate_Day1 = [];



        let conDataHr: any = [];

        for (let i = 0; i < res.length; i++) {
          conDataHr = conDataHr.concat(res[i].heart_rate);
        }

        conDataHr.sort(function (a: any, b: any) {
          return +new Date(a.time) - +new Date(b.time);
        });

        for (let i = 0; i < conDataHr.length; i++) {
          this.dataCalculateHeartRate_Day1.push({
            date: new Date(conDataHr[i].time),
            time_h: new Date(conDataHr[i].time),

            // time_h: new Date(formatDate(conDataHr[i].time, 'yyyy/MM/dd HH:MM', 'en-US')).getHours(),
            time_day: new Date(formatDate(conDataHr[i].time, 'yyyy/MM/dd HH:MM', 'en-US')).getDay(),
            value1: Number(conDataHr[i].value),
            avg: Number(conDataHr[i].value),
          });
        }

        const reduced = this.dataCalculateHeartRate_Day1.reduce(function (m, d) {
          if (!m[d.time_h]) {
            m[d.time_h] = { ...d, count: 1 };
            return m;
          }
          m[d.time_h].value1 += d.value1;
          m[d.time_h].count += 1;
          return m;
        }, {});

        const result = Object.keys(reduced).map(function (k) {
          const item = reduced[k];
          return {
            // time_h: ("00" + item.time_h).slice(-2) + ":00 น",
            time_h: item.time_h,
            avg: item.value1 / item.count,
          }
        });

        this.dataChart24List_Day1 = this.dataCalculateHeartRate_Day1;
        this.dataChart24List_Day1 = this.dataChart24List_Day1.map((item: any) => ({
          ...item,
          date: new Date(new Date(item.date).getTime() - (1000 * 60 * 60 * 7)),
          time_h: new Date(new Date(item.time_h).getTime() - (1000 * 60 * 60 * 7))
        }));

        // for (var i = 0; i < 24; i++) {
        //   var index = result.findIndex(e => e.time_h == i);
        //   if (index > -1) {
        //     this.dataChart24List_Day1.push({
        //       time_h: ("00" + result[index].time_h).slice(-2) + ":00 น",
        //       avg: result[index].avg,
        //       num_hr: result[index].time_h
        //     });
        //   } else {
        //     this.dataChart24List_Day1.push({
        //       time_h: ("00" + i).slice(-2) + ":00 น",
        //       avg: 0,
        //       num_hr: i
        //     });
        //   }
        // }

        for (let index = 0; index < this.dataChart24List_new.length; index++) {
          // this.dataChart24List_new[index].time_h.setDate(18);
          var split_date = datetimes.split('-');
          this.dataChart24List_new[index].time_h.setFullYear(
            split_date[0],
            Number(split_date[1]) - 1,
            split_date[2]
          );
        }

        // console.log("this.dataChart24List_Day1",this.dataChart24List_Day1);

        // this.GraphAver24h(this.dataChart24List_Day1);
        this.GraphAver24hLine(this.dataChart24List_Day1);

      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
      })
  }





  GraphHeartRateDemo() {
    this.changeDetectRef.detectChanges();
    am4core.useTheme(am4themes_animated);

    var chart = am4core.create(this.chartHeartRate.nativeElement, am4charts.XYChart);
    chart.exporting.menu = new am4core.ExportMenu();

    this.dataChartHeartRate = this.dataChartHeartRate.map((item: any) => ({
      ...item,
      date: this.datePipe.transform(item.date, "dd-MM-yyyy H:mm")
    }));
    chart.data = this.dataChartHeartRate;

    let gradient = new am4core.LinearGradient();
    gradient.addColor(am4core.color("#243b55"));
    gradient.addColor(am4core.color("#141e30"));
    gradient.rotation = 90;
    chart.background.fill = gradient;


    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'date';
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.rotation = 300;
    categoryAxis.renderer.grid.template.stroke = am4core.color("#ffffff");
    categoryAxis.renderer.labels.template.fill = am4core.color("#ffffff");

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 30;
    valueAxis.renderer.grid.template.stroke = am4core.color("#ffffff");
    valueAxis.renderer.labels.template.fill = am4core.color("#ffffff");

    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = 'value1';
    series.dataFields.categoryX = 'date';
    series.strokeWidth = 1;
    series.minBulletDistance = 10;
    series.stroke = am4core.color('#64dbfb');
    series.tooltipText = '[bold]{date}:[/] {value1}\n[bold]';

    var rangeareanormal = valueAxis.axisRanges.create();
    rangeareanormal.value = 60;
    rangeareanormal.endValue = 100;
    rangeareanormal.axisFill.fill = am4core.color('#00ba00');
    rangeareanormal.axisFill.fillOpacity = 0.2;
    rangeareanormal.grid.strokeOpacity = 0;



    var axisRange1 = valueAxis.axisRanges.create();
    axisRange1.value = 150;
    axisRange1.grid.strokeOpacity = 1;
    axisRange1.grid.stroke = am4core.color('#f3260e');
    axisRange1.grid.strokeWidth = 2;

    var rangearea1 = valueAxis.axisRanges.create();
    rangearea1.value = 100;
    rangearea1.endValue = 150;
    rangearea1.axisFill.fill = am4core.color('#f3260e');
    rangearea1.axisFill.fillOpacity = 0.2;
    rangearea1.grid.strokeOpacity = 0;

    var axisRange2 = valueAxis.axisRanges.create();
    axisRange2.value = 40;
    axisRange2.grid.strokeOpacity = 1;
    axisRange2.grid.stroke = am4core.color('#f3260e');
    axisRange2.grid.strokeWidth = 2;

    var rangearea2 = valueAxis.axisRanges.create();
    rangearea2.value = 40;
    rangearea2.endValue = 60;
    rangearea2.axisFill.fill = am4core.color('#f3260e');
    rangearea2.axisFill.fillOpacity = 0.2;
    rangearea2.grid.strokeOpacity = 0;


    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = categoryAxis;
  }



  // GraphAver24h(mainData: any[]) {

  //   console.log("24H mainData : ", mainData);
  //   console.log("24H dataChart24List : ", this.dataChart24List);

  //   am4core.useTheme(am4themes_animated);

  //   var chart = am4core.create(this.chartHeartRateAver24.nativeElement, am4charts.XYChart);
  //   chart.exporting.menu = new am4core.ExportMenu();

  //   let newList: any = [];

  //   for (var i = 0; i < mainData.length; i++) {
  //     newList.push({
  //       time_h: mainData[i].time_h,
  //       avg: mainData[i].avg,
  //       "color": chart.colors.next()
  //     })
  //   }

  //   chart.data = newList;

  //   let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  //   categoryAxis.dataFields.category = "time_h";
  //   categoryAxis.renderer.grid.template.location = 0;
  //   categoryAxis.renderer.minGridDistance = 30;
  //   categoryAxis.renderer.labels.template.horizontalCenter = "right";
  //   categoryAxis.renderer.labels.template.verticalCenter = "middle";
  //   categoryAxis.renderer.labels.template.rotation = 270;
  //   categoryAxis.renderer.minHeight = 110;

  //   let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  //   valueAxis.min = 35;
  //   // valueAxis.max = 100;

  //   let series = chart.series.push(new am4charts.ColumnSeries());
  //   series.sequencedInterpolation = true;
  //   series.dataFields.valueY = "avg";
  //   series.dataFields.categoryX = "time_h";
  //   series.tooltipText = "อัตราการเต้น [{categoryX}: bold]{valueY.formatNumber('#.00')}[/]";
  //   series.columns.template.strokeWidth = 0;
  //   series.columns.template.column.cornerRadiusTopLeft = 10;
  //   series.columns.template.column.cornerRadiusTopRight = 10;
  //   series.columns.template.column.fillOpacity = 0.8;
  //   series.columns.template.propertyFields.fill = "color";
  //   series.tooltipPosition = "pointer";


  //   let dateAxis2 = chart.xAxes.push(new am4charts.DateAxis());
  //   dateAxis2.renderer.grid.template.location = 0;
  //   dateAxis2.renderer.minGridDistance = 40;
  //   dateAxis2.renderer.labels.template.disabled = true;
  //   dateAxis2.renderer.grid.template.disabled = true;
  //   dateAxis2.cursorTooltipEnabled = false;

  //   let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
  //   valueAxis2.renderer.opposite = true;
  //   valueAxis2.renderer.grid.template.disabled = true;
  //   valueAxis2.renderer.labels.template.disabled = true;

  //   let series2 = chart.series.push(new am4charts.LineSeries());
  //   series2.dataFields.valueY = "avg";
  //   series2.dataFields.dateX = "num_hr";
  //   series2.data = this.dataChart24List;
  //   series2.xAxis = dateAxis2;
  //   series2.yAxis = valueAxis2;
  //   series2.strokeWidth = 3;
  //   series2.tooltipText = "เฉลี่ย : [bold]{valueY.formatNumber('#.00')}[/]";
  //   series2.stroke = am4core.color('red');

  //   chart.cursor = new am4charts.XYCursor();

  // }




  GraphAver24hLine(mainData: any[]) {
    setTimeout(() => {
      am4core.useTheme(am4themes_animated);
  
      var chart = am4core.create(this.chartHeartRateAver24Line.nativeElement, am4charts.XYChart);
      chart.exporting.menu = new am4core.ExportMenu();
  
      let gradient = new am4core.LinearGradient();
      gradient.addColor(am4core.color("#243b55"));
      gradient.addColor(am4core.color("#141e30"));
      gradient.rotation = 90;
      chart.background.fill = gradient;
  
  
      let newList: any = [];
  
      for (var i = 0; i < mainData.length; i++) {
        newList.push({
          time_h: mainData[i].time_h,
          avg: mainData[i].avg,
          num_hr: i
        })
      }
  
      chart.data = newList;
  
      // let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      // categoryAxis.dataFields.category = "time_h";
      // categoryAxis.renderer.grid.template.location = 0;
      // categoryAxis.renderer.minGridDistance = 30;
      // categoryAxis.renderer.labels.template.horizontalCenter = "right";
      // categoryAxis.renderer.labels.template.verticalCenter = "middle";
      // categoryAxis.renderer.labels.template.rotation = 270;
      // categoryAxis.renderer.minHeight = 110;
      // categoryAxis.renderer.grid.template.stroke = am4core.color("#ffffff");
      // categoryAxis.renderer.labels.template.fill = am4core.color("#ffffff");
  
  
      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.baseInterval = {
        "timeUnit": "minute",
        "count": 1
      };
      dateAxis.renderer.grid.template.stroke = am4core.color("#ffffff");
      dateAxis.renderer.labels.template.fill = am4core.color("#ffffff");
  
      let dateAxis2 = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis2.renderer.grid.template.location = 0;
      dateAxis2.renderer.minGridDistance = 40;
      dateAxis2.renderer.labels.template.disabled = true;
      dateAxis2.renderer.grid.template.disabled = true;
      dateAxis2.cursorTooltipEnabled = false;
  
      let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis2.min = 35;
      valueAxis2.max = 180;
      valueAxis2.renderer.grid.template.stroke = am4core.color("#ffffff");
      valueAxis2.renderer.labels.template.fill = am4core.color("#ffffff");
  
      // var axisRangetop = valueAxis2.axisRanges.create();
      // axisRangetop.value = 10000;
      // axisRangetop.grid.strokeOpacity = 1;
      // axisRangetop.grid.stroke = am4core.color('#00ba00');
      // axisRangetop.grid.strokeWidth = 2;
  
      // var axisRangebottom = valueAxis2.axisRanges.create();
      // axisRangebottom.value = 90;
      // axisRangebottom.grid.strokeOpacity = 1;
      // axisRangebottom.grid.stroke = am4core.color('#f3260e');
      // axisRangebottom.grid.strokeWidth = 2;
  
      // var rangeareatop = valueAxis2.axisRanges.create();
      // rangeareatop.value = 35.4;
      // rangeareatop.endValue = 37.4;
      // rangeareatop.axisFill.fill = am4core.color('#00ba00');
      // rangeareatop.axisFill.fillOpacity = 0.2;
      // rangeareatop.grid.strokeOpacity = 0;
  
      var rangeareabottom = valueAxis2.axisRanges.create();
      rangeareabottom.value = 60;
      rangeareabottom.endValue = 100;
      rangeareabottom.axisFill.fill = am4core.color('#00ba00');
      rangeareabottom.axisFill.fillOpacity = 0.2;
      rangeareabottom.grid.strokeOpacity = 0;
  
  
      let series2 = chart.series.push(new am4charts.LineSeries());
      series2.dataFields.valueY = 'avg';
      series2.dataFields.dateX = 'time_h';
      series2.data = this.dataChart24List_new;
      series2.xAxis = dateAxis;
      series2.yAxis = valueAxis2;
      series2.strokeWidth = 3;
      series2.tooltipText = "Average : [bold]{valueY.formatNumber('#.00')}[/] ครั้ง";
      series2.stroke = am4core.color('#cd0506');
      series2.fill = am4core.color('#cd0506');
  
  
      let series1 = chart.series.push(new am4charts.LineSeries());
      series1.dataFields.valueY = 'avg';
      series1.dataFields.dateX = 'time_h';
      series1.data = newList.reverse();
      series1.xAxis = dateAxis;
      series1.yAxis = valueAxis2;
      series1.strokeWidth = 2;
      series1.tooltipText = "ปัจจุบัน [bold]{valueY.formatNumber('#.00')}[/] ครั้ง";
      series1.stroke = am4core.color('#00bce6');
      series1.fill = am4core.color('#00bce6');
  
      // Add cursor
      chart.cursor = new am4charts.XYCursor();

    })
  }




  GraphAver7days() {

    am4core.useTheme(am4themes_animated);

    var chart = am4core.create(this.chartHeartRateAver7day.nativeElement, am4charts.XYChart);
    chart.exporting.menu = new am4core.ExportMenu();

    chart.data = this.dataChart7DayList;

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "time_day";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    categoryAxis.renderer.labels.template.rotation = 270;
    categoryAxis.renderer.minHeight = 110;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.minWidth = 50;

    let series = chart.series.push(new am4charts.ColumnSeries());
    series.sequencedInterpolation = true;
    series.dataFields.valueY = "avg";
    series.dataFields.categoryX = "time_day";
    series.tooltipText = "[{categoryX}: bold]{valueY.formatNumber('#.00')}[/]";
    series.columns.template.strokeWidth = 0;

    series.columns.template.column.cornerRadiusTopLeft = 10;
    series.columns.template.column.cornerRadiusTopRight = 10;
    series.columns.template.column.fillOpacity = 0.8;

    let hoverState = series.columns.template.column.states.create("hover");
    hoverState.properties.cornerRadiusTopLeft = 0;
    hoverState.properties.cornerRadiusTopRight = 0;
    hoverState.properties.fillOpacity = 1;

    series.fill = am4core.color("#514ab8");

    chart.cursor = new am4charts.XYCursor();
  }



  GraphValPercen() {

    var val1 = (this.countMin / this.dataChartHeartRate.length) * 100;
    var valnormal = (this.countNormal / this.dataChartHeartRate.length) * 100;
    var val4 = (this.countMax / this.dataChartHeartRate.length) * 100;

    am4core.useTheme(am4themes_animated);

    var chart = am4core.create(this.chartHeartRateValPercen.nativeElement, am4charts.XYChart);
    chart.exporting.menu = new am4core.ExportMenu();

    chart.data = [{
      "country": "หัวใจเต้นเร็ว (มากกว่า 100)",
      "visits": val4.toFixed(2),
      "color": chart.colors.next()
    }, {
      "country": "หัวใจเต้นปกติ (60-100)",
      "visits": valnormal.toFixed(2),
      "color": chart.colors.next()
    }, {
      "country": "หัวใจเต้นช้า (ต่ำกว่า 60)",
      "visits": val1.toFixed(2),
      "color": chart.colors.next()
    }];

    //create category axis for years
    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "country";
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.location = 0;

    //create value axis for income and expenses
    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.opposite = true;

    //create columns
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryY = "country";
    series.dataFields.valueX = "visits";
    series.name = "HeartRate";
    series.columns.template.fillOpacity = 0.5;
    series.columns.template.strokeOpacity = 0;
    series.tooltipText = "{categoryY} : {valueX.value.formatNumber('#.00')}%";
    series.columns.template.propertyFields.fill = "color";

    //add chart cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "zoomY";

    //add legend
    chart.legend = new am4charts.Legend();

  }

  onChangeDateTimeEvent(event: any, isFirst: boolean) {
    if (!isFirst) var datetimeStr = formatDate(event.value, 'yyyy-MM-dd', 'en-US').toString();
    else var datetimeStr = formatDate(event, 'yyyy-MM-dd', 'en-US').toString();
    this.loadDatabyDateChart(this.gimeiCode, datetimeStr);
    this.loadDataTickerLogToTable(this.gimeiCode,datetimeStr);

  }

  async loadDataTickerLogToTable(imeiCode: string, datetimes: string) {
    await this.loadTickerLog(imeiCode, datetimes).then(
      (resq: any) => {
        // console.log("loadDataTickerLogToTable",resq);

        this.tickerDataToTableList = [];
        this.tickerDataToTableList = resq;
      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
      }
    );
  }

  loadTickerLog(imeiCode: string, datetimes: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.ipServer}/api/smartwatch_ticker/getlogTickerList?imei=${imeiCode}&type=${this.pageGetDatatype}&date=${datetimes}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: 'json' })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
