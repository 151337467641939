import { DatePipe, formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Inject, Input, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ServiceService } from 'src/app/core/services/service.service';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

@Component({
  selector: 'app-sleep',
  templateUrl: './sleep.component.html',
  styleUrls: ['./sleep.component.scss']
})
export class SleepComponent implements OnInit {

  selectedDateForm: FormGroup;
  @Input() deviceId: any;
  @ViewChild('sleepChart', { static: false }) sleepChart!: ElementRef;
  @ViewChild('deepSleepChart', { static: false }) deepSleepChart!: ElementRef;
  @ViewChild('lightSleepChart', { static: false }) lightSleepChart!: ElementRef;
  @ViewChild('goodSleepChart', { static: false }) goodSleepChart!: ElementRef;
  @ViewChild('theSleepChart', { static: false }) theSleepChart!: ElementRef;
  sleepData: any = [];
  deepSleepData: any = [];
  lightSleepData: any = [];
  goodSleepData: any = [];
  theSleepData: any = [];

  constructor(
    private fb: FormBuilder,
    @Inject(LOCALE_ID) private locale: string,
    private serviceService: ServiceService,
    private changeDetectRef:ChangeDetectorRef,
    private datePipe: DatePipe
  ) {
    this.selectedDateForm = this.createSelectedDateForm();
  }

  ngOnInit(): void {
    var dataStart: string = this.selectedDateForm.value.start;
    var dataEnd: string = this.selectedDateForm.value.end;
    var dataStart_ = formatDate(dataStart, 'yyyy-MM-dd', this.locale);
    var dataEnd_ = formatDate(dataEnd, 'yyyy-MM-dd', this.locale);
    var newStart = new Date(dataStart);
    var newStartAddDay = newStart.setDate(newStart.getDate() - 1);
    dataStart_ = formatDate(newStartAddDay, 'yyyy-MM-dd', this.locale);
    var newEnd = new Date(dataEnd);
    var newEndAddDay = newEnd.setDate(newEnd.getDate() + 1);
    dataEnd_ = formatDate(newEndAddDay, 'yyyy-MM-dd', this.locale);
    this.getSleepData(dataStart_, dataEnd_);
  }

  createSelectedDateForm() {
    return this.fb.group({
      start: new FormControl(new Date()),
      end: new FormControl(new Date())
    });
  }

  getSleepData(start: string, end: string) {
    this.sleepData = [];
    this.deepSleepData = [];
    this.lightSleepData = [];
    this.goodSleepData = [];
    this.serviceService.GetSleepList_(this.deviceId).then((res) => {
      console.log(res);
      
      if (res.length > 0) {
        for (let i = 0; i < res.length; i++) {
          var trueDate: Date = new Date(res[i].endTime != 'NaN' ? (res[i].endTime.length == 10 ? Number(res[i].endTime) * 1000 : Number(res[i].endTime)) : res[i].time_log);
          if (res[i].endTime == 'NaN') trueDate.setHours(trueDate.getHours() - 7);
          var mainText: string = Number(Number(res[i].allSleep / 60).toFixed(2).toString().split(".")[0]).toString();
          var decimalText: string = ((Number(Number(res[i].allSleep / 60).toFixed(2).toString().split(".")[1]) / 100) * 60).toFixed(0).toString();
          var mergedText: string = mainText + " Hr " + decimalText + " Min";
          this.sleepData.push({date: this.datePipe.transform(trueDate, "yyyy-MM-dd HH:mm"), allSleep: Number(res[i].allSleep / 60).toFixed(2), text: mergedText});
          var mainTextDeep: string = Number(Number(res[i].deepSleep / 60).toFixed(2).toString().split(".")[0]).toString();
          var decimalTextDeep: string = ((Number(Number(res[i].deepSleep / 60).toFixed(2).toString().split(".")[1]) / 100) * 60).toFixed(0).toString();
          var mergedTextDeep: string = mainTextDeep + " Hr " + decimalTextDeep + " Min";
          this.deepSleepData.push({date: this.datePipe.transform(trueDate, "yyyy-MM-dd HH:mm"), deepSleep: Number(res[i].deepSleep / 60).toFixed(2), text: mergedTextDeep});
          var mainTextLight: string = Number(Number(res[i].lightSleep / 60).toFixed(2).toString().split(".")[0]).toString();
          var decimalTextLight: string = ((Number(Number(res[i].lightSleep / 60).toFixed(2).toString().split(".")[1]) / 100) * 60).toFixed(0).toString();
          var mergedTextLight: string = mainTextLight + " Hr " + decimalTextLight + " Min";
          this.lightSleepData.push({date: this.datePipe.transform(trueDate, "yyyy-MM-dd HH:mm"), lightSleep: Number(res[i].lightSleep / 60).toFixed(2), text: mergedTextLight});
          var mainTextGood: string = Number(Number(res[i].rem / 60).toFixed(2).toString().split(".")[0]).toString();
          var decimalTextGood: string = ((Number(Number(res[i].rem / 60).toFixed(2).toString().split(".")[1]) / 100) * 60).toFixed(0).toString();
          var mergedTextGood: string = mainTextGood + " Hr " + decimalTextGood + " Min";
          this.goodSleepData.push({date: this.datePipe.transform(trueDate, "yyyy-MM-dd HH:mm"), goodSleep: Number(res[i].rem / 60).toFixed(2), text: mergedTextGood});
          this.theSleepData.push({date: this.datePipe.transform(trueDate, "yyyy-MM-dd HH:mm"), allSleep: Number(res[i].allSleep / 60).toFixed(2), allSleepText: mergedText, deepSleep: Number(res[i].deepSleep / 60).toFixed(2), deepSleepText: mergedTextDeep, lightSleep: Number(res[i].lightSleep / 60).toFixed(2), lightSleepText: mergedTextLight, goodSleep: Number(res[i].rem / 60).toFixed(2), goodSleepText: mergedTextGood, startTime: new Date(Number(res[i].startTime)).toISOString()});
        }
        this.sleepData = this.sleepData.filter((row: any) => Date.parse(start) <= Date.parse(row.date) && Date.parse(end) >= Date.parse(row.date));
        this.deepSleepData = this.deepSleepData.filter((row: any) => Date.parse(start) <= Date.parse(row.date) && Date.parse(end) >= Date.parse(row.date));
        this.lightSleepData = this.lightSleepData.filter((row: any) => Date.parse(start) <= Date.parse(row.date) && Date.parse(end) >= Date.parse(row.date));
        this.goodSleepData = this.goodSleepData.filter((row: any) => Date.parse(start) <= Date.parse(row.date) && Date.parse(end) >= Date.parse(row.date));
        this.theSleepData = this.theSleepData.filter((row: any) => Date.parse(start) <= Date.parse(row.date) && Date.parse(end) >= Date.parse(row.date));
        this.sleepData.sort((a : any, b : any) => Number(new Date(a.date)) - Number(new Date(b.date)));
        this.deepSleepData.sort((a : any, b : any) => Number(new Date(a.date)) - Number(new Date(b.date)));
        this.lightSleepData.sort((a : any, b : any) => Number(new Date(a.date)) - Number(new Date(b.date)));
        this.goodSleepData.sort((a : any, b : any) => Number(new Date(a.date)) - Number(new Date(b.date)));
        this.theSleepData.sort((a : any, b : any) => Number(new Date(a.date)) - Number(new Date(b.date)));
        this.theSleepData.sort((a : any, b : any) => Number(new Date(a.date)) - Number(new Date(b.date)));
        
        
        this.theSleepGraph();
      }
    }, (err) => {
      console.log("err -> ", err);
    });
  }

  selectedDateChange() {
    var dataStart: string = this.selectedDateForm.value.start;
    var dataEnd: string = this.selectedDateForm.value.end;
    if (this.selectedDateForm.value.start != null && this.selectedDateForm.value.end != null) {
      var dataStart_ = formatDate(dataStart, 'yyyy-MM-dd', this.locale);
      var dataEnd_ = formatDate(dataEnd, 'yyyy-MM-dd', this.locale);
      var newStart = new Date(dataStart);
      var newStartAddDay = newStart.setDate(newStart.getDate() - 1);
      dataStart_ = formatDate(newStartAddDay, 'yyyy-MM-dd', this.locale);
      var newEnd = new Date(dataEnd);
      var newEndAddDay = newEnd.setDate(newEnd.getDate() + 1);
      dataEnd_ = formatDate(newEndAddDay, 'yyyy-MM-dd', this.locale);
      this.getSleepData(dataStart_, dataEnd_);
    }
  }

  theSleepGraph() {
    this.changeDetectRef.detectChanges();
    am4core.useTheme(am4themes_animated);
    var chart = am4core.create(this.theSleepChart.nativeElement, am4charts.XYChart);
    chart.exporting.menu = new am4core.ExportMenu();
    chart.colors.step = 2;
    chart.legend = new am4charts.Legend()
    chart.legend.position = 'top'
    chart.legend.paddingBottom = 20
    chart.legend.labels.template.maxWidth = 95
    this.theSleepData = this.theSleepData.map((item: any) => ({
      ...item,
      date: `${this.datePipe.transform(item.date, "dd-MM-yyyy H:mm")}`,
      xText: `นอน : ${this.datePipe.transform(item.startTime, "dd-MM-yyyy H:mm")}\nตื่น : ${this.datePipe.transform(item.date, "dd-MM-yyyy H:mm")}`,
    }));
    chart.data = this.theSleepData;
    var xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
    xAxis.dataFields.category = 'date'
    xAxis.renderer.cellStartLocation = 0.1
    xAxis.renderer.cellEndLocation = 0.9
    xAxis.renderer.grid.template.location = 0;
    xAxis.renderer.labels.template.adapter.add("text", function(text: any, target: any) {
      if (target.dataItem && target.dataItem.dataContext) {
        return target.dataItem.dataContext.xText;
      }
      return text;
    });
    var yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.min = 0;
    function createSeries(value: string, name: string, text: string) {
      var series = chart.series.push(new am4charts.ColumnSeries())
      series.dataFields.valueY = value
      series.dataFields.categoryX = 'date'
      series.name = name
      series.columns.template.tooltipText = `${value == "allSleep" ? "[bold]{date}:[/] " : ""}{${text}} [bold](${name})[/]`;
      series.events.on("hidden", arrangeColumns);
      series.events.on("shown", arrangeColumns);
      var bullet = series.bullets.push(new am4charts.LabelBullet())
      bullet.interactionsEnabled = false
      bullet.dy = 30;
      bullet.label.text = `{${text}}`
      bullet.label.fill = am4core.color('#ffffff')
      return series;
    }
    function arrangeColumns() {
      var series: any = chart.series.getIndex(0);
      var w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
      if (series.dataItems.length > 1) {
        var x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
        var x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
        var delta = ((x1 - x0) / chart.series.length) * w;
        if (am4core.isNumber(delta)) {
          var middle = chart.series.length / 2;
          var newIndex = 0;
          chart.series.each(function(series) {
            if (!series.isHidden && !series.isHiding) {
              series.dummyData = newIndex;
              newIndex++;
            }
            else {
              series.dummyData = chart.series.indexOf(series);
            }
          })
          var visibleCount = newIndex;
          var newMiddle = visibleCount / 2;
          chart.series.each(function(series) {
            var trueIndex = chart.series.indexOf(series);
            var newIndex = series.dummyData;
            var dx = (newIndex - trueIndex + middle - newMiddle) * delta
            series.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
            series.bulletsContainer.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
          })
        }
      }
    }
    createSeries("allSleep", "All Sleep", "allSleepText");
    createSeries("deepSleep", "Deep Sleep", "deepSleepText");
    createSeries("lightSleep", "Light Sleep", "lightSleepText");
    createSeries("goodSleep", "REM", "goodSleepText");
    
  }
}
