<app-ai-charts *ngIf="aiCharts" [imei]="imeicode" (md)="turnModal($event)"></app-ai-charts>
<section class="content-header" style="padding-bottom: 0px;">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-1">
        <h1 style="text-align: center;">Profile</h1>
      </div>
      <div class="col-md-11">
        <hr>
      </div>
    </div>
  </div>
</section>
<section class="content ">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <mat-card class="mat-elevation-z8">
          <!-- /.card-header -->
          <div class="card-body" style="display: block; position: relative;">
            <button (click)="callAICharts()" class="btn btn-info position-absolute" style="top: 0; right: 0; z-index: 1;"><i class="fas fa-chart-bar mr-2"></i>AI Charts</button>
            <div class="row">
              <div class="col-md" *ngIf="phone">
                <div class="text-center">
                  <div *ngIf="dataUser[0].userPic != null && dataUser[0].userPic != '' ; else elseImg">
                    <img class="profile-user-img img-fluid img-circle"
                      src="{{imageUrl2}}/{{dataUser[0].userPic}}"
                      onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                      style="width: 160px;height: 160px; object-fit: cover;" alt="User profile picture">
                  </div>
                  <ng-template #elseImg>
                    <img src="assets/adminlte/dist/img/nouser.svg" class="img-circle elevation-2" width="60px"
                      height="60px" alt="img-profile" style="width: 160px;height: 160px; object-fit: cover;"
                      alt="User profile picture">
                  </ng-template>
                </div>
                <h3 *ngIf="dataUser !=null" class="profile-username text-center mt-2"
                  style="margin-bottom: 0px; font-size: 24px;">{{ dataUser[0].userName }}</h3>
                <p *ngIf="dataUser !=null" class="text-muted text-center mt-2" style="font-size: 18px;">{{
                  dataUser[0].userPhone }}</p>
                <p class="text-muted text-center mt-2" style="font-size: 18px;">Device : <b>{{ getTypeDevice }}</b></p>
              </div>
              <div class="col-md" *ngIf="device && dataDevice != null">
                <div class="text-center">
                  <img class="profile-user-img img-fluid img-circle"
                    src="{{imageUrl2}}/{{dataDevice.dataDevice.devicePic}}"
                    onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                    style="width: 160px;height: 160px; object-fit: cover;" alt="User profile picture">

                </div>
                <h3 *ngIf="dataDevice.dataDevice !=null" class="profile-username text-center mt-2"
                  style="margin-bottom: 0px; font-size: 24px;">{{ dataDevice.dataDevice.deviceName }}</h3>
                  <!-- {{ dataDevice.dataDevice.devicePhone }} -->
                <p *ngIf="dataDevice.dataDevice !=null && dataWatchConnectPhone != null" class="text-muted text-center mt-2" style="font-size: 18px;">{{dataWatchConnectPhone[0].userPhone}}</p>
                <p class="text-muted text-center mt-2" style="font-size: 18px;">Device : <b>{{ getTypeDevice }}</b></p>
              </div>
              <!-- /.col -->
              <div class="col-md-10">
                <div class="row">
                  <div class="col-md-12" *ngIf="phone">
                    <div class="row">
                      <div class="col-6">
                        <strong style="font-size: 22px;"><i class="fas fa-user"></i> User
                          Profile</strong>
                      </div>
                      <div class="col-6" style="text-align: end;">
                        <!-- <a matTooltip="เพิ่มรายการโปรด" *ngIf="btnFavorite == false" href="javascript:void(0)"
                          style="color: black;" (click)="onClickFavorite(dataUser[0].userId,'',true)"><i
                            class="tada far fa-star" style="font-size: 25px;"></i></a>
                        <a matTooltip="ยกเลิกรายการโปรด" *ngIf="btnFavorite == true" href="javascript:void(0)"
                          style="color: black;" (click)="onClickFavorite(dataUser[0].userId,'',false)"><i
                            class="tada fas fa-star" style="font-size: 25px; color: #e3bb1c;"></i></a> -->
                      </div>

                    </div>
                    <div class="col-md-12" style="padding-top: 10px;" *ngIf="dataUser !=null">
                      <div class="row">
                        <div class="col-md">
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">Name : <b>{{ dataUser[0].userName
                                }}</b></span>
                          </p>
                        </div>
                        <div class="col-md">
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">Email : <b>{{ dataUser[0].userEmail
                                }}</b></span>
                          </p>
                        </div>
                        <div class="col-md">
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">Date of birth : <b>{{
                                dataUser[0].userDate | date:'shortDate' }}</b></span>
                          </p>
                        </div>
                        <div class="col-md">
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">Phone : <b>{{ dataUser[0].userPhone
                                }}</b></span>
                          </p>
                        </div>
                        <div class="col-md">
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">Hospital : <b>{{!hospital ? "-" : hospital}}</b></span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12" *ngIf="dataUser !=null">
                      <div class="row">
                        <div class="col-md">
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">Age :
                              <b>{{dataUser[0].userAge}}</b></span>
                          </p>
                        </div>
                        <div class="col-md">
                          <p class="text-muted">
                            <span *ngIf="dataUser[0].userSex == 'M'" class="tag tag-danger"
                              style="font-size: 16px;">Gender
                              : <b>Male</b></span>
                            <span *ngIf="dataUser[0].userSex == 'F'" class="tag tag-danger"
                              style="font-size: 16px;">Gender
                              : <b>FeMale</b></span>
                          </p>
                        </div>
                        <div class="col-md">
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">Weight : <b>{{ dataUser[0].userWeight
                                }} kg.</b></span>
                          </p>
                        </div>
                        <div class="col-md">
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">Height : <b>{{ dataUser[0].userHeight
                                }} cm.</b></span>
                          </p>
                        </div>
                        <div class="col-md">
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">Blood type : <b>{{
                                dataUser[0].userBloodtype }}</b></span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12" *ngIf="dataUser !=null">
                      <div class="row">
                        <div class="col-md">
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">Address : <b>{{
                                dataUser[0].userAddress
                                }}</b></span>
                          </p>
                        </div>
                        <div class="col-md">
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">Subdistrict : <b>{{
                                dataUser[0].userSubdistrict }}</b></span>
                          </p>
                        </div>
                        <div class="col-md">
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">District : <b>{{
                                dataUser[0].userDistrict }}</b></span>
                          </p>
                        </div>
                        <div class="col-md">
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">Province : <b>{{
                                dataUser[0].userProvince }}</b></span>
                          </p>
                        </div>
                        <div class="col-md">
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">Postalcode : <b>{{
                                dataUser[0].userPostalcode }}</b></span>
                          </p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md">
                          <!-- <img class="img-circle mat-elevation-z8 mr-1" src="assets\adminlte\dist\img\logon.png" style="width: 32px;height: 32px;" alt="icon-BMI"> -->

                          <!-- <span class="tag tag-danger" style="font-size: 16px;"><b>BMI : </b></span>
                          <span class="tag tag-danger" style="font-size: 16px;"> {{bmi}}</span> -->
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">BMI : <b>{{bmi}}</b></span>
                          </p>
                        </div>
                        <div class="col-md">
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">Package : <b>{{package ? package.detailPackage.name : "-"}}</b></span>
                          </p>
                        </div>
                        <div class="col-md">
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">Tele Free : <b>{{package ? package.detailPackageResult.ustockFreeTele : 0}}</b></span>
                          </p>
                        </div>
                        <div class="col-md">
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">Care Support : <b>{{package ? package.detailPackageResult.ustockTelReport : 0}}</b></span>
                          </p>
                        </div>
                        <div class="col-md"></div>
                      </div>
                    </div>
                    <hr>

                    <div class="col-md-12" *ngIf="dataUser !=null">
                      <div><strong style="font-size: 22px;"><img src="assets/adminlte/dist/img/smart-watch.svg"
                            width="25px" height="25px" alt=""> Connect Watch</strong></div>
                      <div class="row" style="padding-top: 10px;"
                        *ngFor="let item of dataPhoneConnectWatch; index as i">

                        <div class="col-md-3">
                          <div class="row">
                            <img class="img-circle mat-elevation-z8"
                              src="{{imageUrl2}}/{{item.dataDevice.devicePic}}"
                              onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                              style="width: 40px;height: 40px; object-fit: cover;" alt="User profile picture">
                            <div>
                              <div class="row" style="margin-top: auto;margin-bottom: auto; margin-left: 5px;">
                                Device Name : <b>&nbsp;{{ item.dataDevice.deviceName }}</b>
                              </div>
                              <div class="row" style="margin-left: 5px;">
                                <div *ngIf="item.watch == 'B10' || item.watch == 'BLUETOOTH' || item.watch == 'E66'">
                                  Device : <b>{{item.watch}}</b>
                                </div>
                                <div *ngIf="item.watch == 'hw'">
                                  Device : <b>Huawei</b>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2" style="margin-top: auto;margin-bottom: auto">
                          <div class="row" *ngIf="showStatus">
                            <div *ngIf="showStatus.onlineStatus && showStatus.wearStatus == 'Yes'">
                              Device status : <b style="color: #14ad14;">Online</b>
                            </div>
                            <div *ngIf="showStatus.onlineStatus && showStatus.wearStatus != 'Yes'">
                              Device status : <b style="color: #ffc107;">Online, Not wear</b>
                            </div>
                            <div *ngIf="!showStatus.onlineStatus && showStatus.wearStatus != 'Yes'">
                              Device status : <b style="color: #d00d0d;">Offline</b>
                            </div>
                          </div>
                          <div class="row">
                            <div *ngIf="item.dataDevice.swcnDatetimeOffline != null && (item.watch == 'B10' || item.watch == 'BLUETOOTH' || item.watch == 'E66')">
                              Latest update : <b>{{item.dataDevice.swcnDatetimeOffline | timeago }}</b>
                            </div>
                            <div *ngIf="item.dataDevice.swcnDatetimeOffline == null && (item.watch == 'B10' || item.watch == 'BLUETOOTH' || item.watch == 'E66' )">
                              Latest update : <b>no time to send</b>
                            </div>
                            <div *ngIf="item.watch == 'hw' && item.status == 'offline'">
                              Latest update : <b>{{item.time}}</b>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-7" style="margin-top: auto;margin-bottom: auto">
                          <div class="row">
                            <div>
                              Device Serial : <b>{{ item.dataDevice.deviceSerial }}</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr style="margin-top: 5px;margin-bottom:5px;">
                    </div>

                  </div>
                  <div class="col-md-12" *ngIf="device">
                    <div><strong style="font-size: 22px;"><i class="fas fa-user"></i>
                        Devices</strong></div>
                    <div style="padding-top: 10px;" class="col-md-12" *ngIf="dataDevice !=null">
                      <div class="row">
                        <div class="col-md-12">
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;word-wrap: break-word;">Device Serial :
                              <b>{{
                                dataDevice.dataDevice.deviceSerial }}</b></span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12" *ngIf="dataDevice !=null">
                      <div class="row">
                        <div class="col-md-3">
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">Device Name : <b>{{
                                dataDevice.dataDevice.deviceName }}</b></span>
                          </p>
                        </div>
                        <div class="col-md-3">
                          <p class="text-muted" *ngIf="showStatus">
                            <span *ngIf="showStatus.onlineStatus && showStatus.wearStatus == 'Yes'" class="tag tag-danger"
                            style="font-size: 16px;">Device status : <b style="color: #14ad14;">Online</b></span>
                            <span *ngIf="showStatus.onlineStatus && showStatus.wearStatus != 'Yes'" class="tag tag-danger"
                              style="font-size: 16px;">Device status : <b style="color: #ffc107;">Online, Not wear</b></span>
                            <span *ngIf="!showStatus.onlineStatus && showStatus.wearStatus != 'Yes'" class="tag tag-danger"
                              style="font-size: 16px;">Device status : <b style="color: #d00d0d;">Offline</b></span>
                          </p>
                        </div>
                        <div class="col-md-3">
                          <p class="text-muted">
                            <span class="tag tag-danger"
                              *ngIf="dataDevice.dataDevice.swcnDatetimeOffline != null && dataDevice.watch == 'B10' || dataDevice.watch == 'BLUETOOTH' || dataDevice.watch == 'E66' "
                              style="font-size: 16px;">Device Latest update :
                              <b>{{dataDevice.dataDevice.swcnDatetimeOffline | timeago }}</b></span>
                            <span class="tag tag-danger"
                              *ngIf="dataDevice.watch == 'hw' && dataDevice.status == 'offline'"
                              style="font-size: 16px;">Device Latest update : <b>{{dataDevice.time}}</b></span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12" *ngIf="dataDevice !=null">
                      <div class="row">
                        <div class="col-md-2">
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">Age :
                              <b>{{dataDevice.dataDevice.deviceAge}}</b></span>
                          </p>
                        </div>
                        <div class="col-md-2">
                          <p class="text-muted">
                            <span class="tag tag-danger" *ngIf="dataDevice.dataDevice.deviceSex == 'M'"
                              style="font-size: 16px;">Gender : <b>Male</b></span>
                            <span class="tag tag-danger" *ngIf="dataDevice.dataDevice.deviceSex == 'FM'"
                              style="font-size: 16px;">Gender : <b>Female</b></span>
                          </p>
                        </div>
                        <div class="col-md-2">
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">Bloodtype : <b>{{
                                dataDevice.dataDevice.deviceBloodtype }}</b></span>
                          </p>
                        </div>
                        <div class="col-md-2">
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">Weight : <b>{{
                                dataDevice.dataDevice.deviceWeight }} kg.</b></span>
                          </p>
                        </div>
                        <div class="col-md-2">
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">Height : <b>{{
                                dataDevice.dataDevice.deviceHeight }} cm.</b></span>
                          </p>
                        </div>

                      </div>
                    </div>
                    <div class="col-md-12" *ngIf="dataDevice !=null">
                      <div class="row">
                        <div class="col-md-2">
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">Address :
                              <b>{{dataDevice.dataDevice.deviceAddress}}</b></span>
                          </p>
                        </div>
                        <div class="col-md-2">
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">SubDistrict : <b>{{
                                dataDevice.dataDevice.deviceSubdistrict }}</b></span>
                          </p>
                        </div>
                        <div class="col-md-2">
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">District : <b>{{
                                dataDevice.dataDevice.deviceDistrict }}</b></span>
                          </p>
                        </div>
                        <div class="col-md-2">
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">Province : <b>{{
                                dataDevice.dataDevice.deviceProvince }}</b></span>
                          </p>
                        </div>
                        <div class="col-md-2">
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">Postalcode : <b>{{
                                dataDevice.dataDevice.userPostalcode }}</b></span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12" *ngIf="dataDevice !=null">
                      <div class="row">
                        <div class="col-md-2">
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">Emergency Contact 1 : <b>{{
                                dataDevice.dataDevice.devicePhone }}</b></span>
                          </p>
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">Contact Name 1 : <b>{{
                              dataDevice.dataDevice.devicePhoneName ? dataDevice.dataDevice.devicePhoneName : "-" }}</b></span>
                          </p>
                        </div>
                        <div class="col-md-2">
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">Emergency Contact 2 : <b>{{
                                dataDevice.dataDevice.devicePhone2 }}</b></span>
                          </p>
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">Contact Name 2 : <b>{{
                              dataDevice.dataDevice.devicePhoneName ? dataDevice.dataDevice.devicePhoneName2 : "-" }}</b></span>
                          </p>
                        </div>
                        <div class="col-md-2">
                          <p class="text-muted">
                            <span class="tag tag-danger" style="font-size: 16px;">Hospital : <b>{{!hospital ? "-" : hospital}}</b></span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr>

                    <div class="col-md-12" *ngIf="dataDevice !=null">
                      <div><strong style="font-size: 22px;"><img src="assets/adminlte/dist/img/smartphones.svg"
                            width="25px" height="25px" alt=""> Connect Phone</strong></div>
                      <div class="row" style="padding-top: 10px;"
                        *ngFor="let item of dataWatchConnectPhone; index as i">
                        <div class="col-md-3">
                          <div class="row">
                            <img class="img-circle" src="{{imageUrl2}}/{{item.userPic}}"
                              onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                              style="width: 40px;height: 40px; object-fit: cover;" alt="User profile picture">

                            <div style="margin-top: auto;margin-bottom: auto; margin-left: 5px;">
                              Name : <b>{{ item.userName }}</b>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2" style="margin-top: auto;margin-bottom: auto">
                          <div class="row">
                            <div>
                              Phone : <b>{{ item.userPhone }}</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr style="margin-top: 5px;margin-bottom:5px;">
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</section>

<section class="content mt-2 mr-2 ml-2">
  <div class="mat-elevation-z8 card card-solid">
    <div class="card-body pb-0">
      <mat-tab-group [selectedIndex]="tabIndex" (selectedTabChange)="changeTab($event)">
        <mat-tab label="SOS History" >
          <div class="card-body pb-0" style="padding: 0px;">
            <div class="row" *ngIf="dataSOSHistory != null">
              <div class="col-12 col-sm-6 col-md-3 d-flex align-items-stretch flex-column mt-3" style="height: 275px;"
                *ngFor="let item of dataSOSHistory;let i = index">
                <div class="card bg-light d-flex flex-fill mat-elevation-z8">
                  <div class="card-header text-muted border-bottom-0" style="background-color: #e0e0e0;">
                    <b>ID-Case :</b> {{item.ojobId}}
                  </div>
                  <hr style="padding: 0px;margin: 0px;">
                  <div class="card-body pt-3">
                    <div class="row">
                      <div class="col">
                        <p class="text-muted text-sm"><i class="fas fa-map-marked-alt"></i><b> Location: </b>
                          {{item.ojobAddress ? item.ojobAddress : "-"}}</p>
                        <ul class="ml-4 mb-0 fa-ul text-muted" style="font-size: 18px;">
                          <li class="small"><span class="fa-li"><i class="fas fa-clock"></i></span> <b>DateTime</b>:
                            <span class="right badge badge-danger"><i class="fas fa-exclamation-circle"></i>
                              {{item.ojobDate | date:'short'}}</span>
                          </li>
                          <li class="small"><span class="fa-li"><i class="fas fa-question-circle"></i></span> <b>SOS Type</b>:
                            <span class="right badge badge-danger" style="font-size: 14px;">{{item.sos_type}}</span>
                          </li>
                          <li class="small"><span class="fa-li"><i class="fas fa-heartbeat"></i></span> <b>Heart Rate</b>:
                            <span>{{item.heartrate_val}}</span>
                          </li>
                          <li class="small"><span class="fa-li"><i class="fas fa-thermometer-half"></i></span> <b>Temperature</b>:
                            <span>{{item.temperature_val}}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer" style="padding: 5px;">
                    <div class="text-right">
                      <button mat-mini-fab color="primary" (click)="openDialogLogSosHistory(item)">
                        <i class="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container-fluid" *ngIf="dataSOSHistory.length <= 0">
                <mat-card class="mat-elevation-z8">
                  <div class="col-md-12">
                    <div class="mb-2">
                      <div style="display: flex;">
                        <div class="container rubberBand"
                          style="color: #bdbbb7;margin-top: 100px;margin-bottom: 100px;text-align: center;">
                          <i class="fas fa-calendar-times" style="font-size: 50px;"></i>
                          <p>No Notification.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab *ngIf="getTypeDevice !== 'phone' && getTypeDevice !== 'EVIEW' && getTypeDevice !== 'RADAR'" label="SpO2">
          <app-sp-o2 *ngIf="tabIndex === 1"></app-sp-o2>
        </mat-tab>
        
        <mat-tab *ngIf="getTypeDevice !== 'phone' && getTypeDevice !== 'EVIEW' && getTypeDevice !== 'RADAR'" label="Body Temperature">
          <app-body-temp *ngIf="tabIndex === 2"></app-body-temp>
        </mat-tab>
        <mat-tab *ngIf="getTypeDevice !== 'phone' && getTypeDevice !== 'EVIEW' && getTypeDevice !== 'RADAR'" label="Step Count">
          <app-step-count *ngIf="tabIndex === 3"></app-step-count>
        </mat-tab>
        <mat-tab *ngIf="getTypeDevice !== 'phone' && getTypeDevice !== 'EVIEW' && getTypeDevice !== 'RADAR'" label="Heart Rate">
          <app-heart-rate *ngIf="tabIndex === 4"></app-heart-rate>
        </mat-tab>
        <mat-tab *ngIf="getTypeDevice !== 'phone' && getTypeDevice !== 'EVIEW' && getTypeDevice !== 'RADAR'" label="Blood Pressure">
          <app-bloodpressure *ngIf="tabIndex === 5"></app-bloodpressure>
        </mat-tab>


        <mat-tab *ngIf="getTypeDevice != 'phone' && dataDevice.watch != 'B10' && getTypeDevice !== 'EVIEW' && getTypeDevice !== 'RADAR'" label="Respiratory Rate">
          <app-respiratory-rate *ngIf="tabIndex === 6" [imei]="imeicode"></app-respiratory-rate>
        </mat-tab>
        <mat-tab *ngIf="getTypeDevice !== 'phone' && getTypeDevice !== 'EVIEW' && getTypeDevice !== 'RADAR'" label="Stress">
          <app-stress *ngIf="dataDevice.watch != 'B10' ? tabIndex == 7 : tabIndex == 6"></app-stress>
        </mat-tab>
        <mat-tab *ngIf="getTypeDevice !== 'phone' && getTypeDevice !== 'EVIEW' && getTypeDevice !== 'RADAR'" label="Fatigue">
          <app-fatigue *ngIf="dataDevice.watch != 'B10' ? tabIndex == 8 : tabIndex == 7"></app-fatigue>
        </mat-tab>
        <mat-tab *ngIf="getTypeDevice !== 'phone' && getTypeDevice !== 'EVIEW' && getTypeDevice !== 'RADAR'" label="Sleep">
          <app-sleep *ngIf="dataDevice.watch != 'B10' ? tabIndex == 9 : tabIndex == 8" [deviceId]="dataDevice != null ? dataDevice.dataDevice.deviceSerial : null"></app-sleep>
        </mat-tab>
        <mat-tab *ngIf="getTypeDevice != 'phone' && role != 'Filter'" label="Location">
          <app-location *ngIf="dataDevice.watch != 'B10' ? (getTypeDevice != 'EVIEW' && getTypeDevice != 'RADAR' ? tabIndex == 10 : tabIndex == 1) : tabIndex == 9" [imei]="imeicode" (pageEmit)="getPage($event)"></app-location>
        </mat-tab>
        <mat-tab *ngIf="getTypeDevice == 'phone'" label="Order History">
          <div class="p-3">
            <table class="table table-borderless table-hover table-light">
              <thead class="font-weight-bold bg-light">
                <tr>
                  <td class="text-center">#</td>
                  <td>Package Name</td>
                  <td>Tele Free</td>
                  <td>Care Support</td>
                  <td>Type</td>
                  <td>Generate Date</td>
                  <td>Activate By</td>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of orderHistoryObs | async">
                  <td class="text-center">{{row.index}}</td>
                  <td>
                    <div class="d-flex flex-row">
                      <div class="mr-3">
                        <img src="{{imageUrl3}}/{{row.dataPackageUseToUserDetail.userdetailImg}}" class="img-prop" onerror="this.src='assets/adminlte/dist/img/smart-watch.svg'">
                      </div>
                      <div>{{row.dataPackageUseToUserDetail.userdetailName}}</div>
                    </div>
                  </td>
                  <td>{{row.dataPackageUseToUserDetail.userdetailFreeTele}}</td>
                  <td>{{row.dataPackageUseToUserDetail.userdetailTelReport}}</td>
                  <td>{{row.dataPackageUseToUser.mainPackage ? "แพ็กเกจหลัก" : "แพ็กเกจรอง"}}</td>
                  <td>{{row.dataPackageUseToUser.genaratedDate | date: "dd/MM/yyyy H:mm"}}</td>
                  <td>
                    <span class="badge badge-pill" [ngClass]="row.dataPackageUseToUser.activateBy == 'InApp' ? 'badge-primary' : 'badge-success'" style="font-size: 12px;">{{row.dataPackageUseToUser.activateBy == "InApp" ? "App" : "Package"}}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <mat-paginator *ngIf="orderHistory.length" [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</section>