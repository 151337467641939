import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AiChartsComponent } from '../admin/pages/users-profile/modal/ai-charts/ai-charts.component';
import { NgApexchartsModule } from 'ng-apexcharts';

@NgModule({
  declarations: [
    AiChartsComponent
  ],
  imports: [
    CommonModule,
    NgApexchartsModule,
  ],
  exports: [
    AiChartsComponent
  ]
})
export class SharedModule { }
