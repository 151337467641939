import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AngularMaterialModule } from './shared/material/angular-material.module';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent, DialogLoginOut } from './shared/layout/header/header.component';
import { FooterComponent } from './shared/layout/footer/footer.component';
import { MenuComponent } from './shared/layout/menu/menu.component';
import { UsersProfileComponent } from './admin/pages/users-profile/users-profile.component';
import { DialogEditProfileOperator } from './admin/pages/operators-profile/edit-operator-profile.component';
import { LoginComponent } from './admin/pages/login/login.component';
import { AdminDistrictComponent, DialogAddAdminDistrict } from './admin/pages/admin-district/admin-district.component';
import { AdminSubDistrictComponent, DialogAddAdminSubDistrict } from './admin/pages/admin-sub-district/admin-sub-district.component';
import { DialogEditAdminDistrictDialog } from './admin/pages/admin-district/edit-admin-district.component';
import { DialogEditAdminSubDistrictDialog } from './admin/pages/admin-sub-district/edit-admin-sub-district.component';
import { CaseProfileComponent } from './admin/pages/case-profile/case-profile.component';
import { CaseProfileAppComponent } from './admin/pages/case-profile/case-profile-app/case-profile-app.component';
import { DialogSuccessJob } from './admin/pages/case-profile/models/succcess-job/success-job.component';
import { DialogDetailSos } from './shared/modal/detailCovid/detail-sos.component';
import { DialogPersonalHealth } from './shared/modal/personalHealth/personal-health.component';
import { DialogLogSosHistory } from './admin/pages/users-profile/modal/logsos/log-sos.component';
import { DialogCovid19Chart } from './admin/pages/covid/user-covid19/modal/chart-covid/chart-covid.component';
import { ConnectApiErrorComponent } from './shared/snackBar/connect-api-error/connect-api-error.component';
import { UserCovid19ListComponent } from './admin/pages/user-covid19-list/user-covid19-list.component';
import { DoctorNotificationComponent } from './admin/pages/covid/doctor-notification/doctor-notification.component';
import { DoctorMallComponent } from './admin/pages/covid/doctor-mall/doctor-mall.component';
import { DoctorPatientComponent } from './admin/pages/covid/doctor-patient/doctor-patient.component';
import { DialogConfirmMallUser } from './admin/pages/covid/doctor-mall/modal/confirm-mall-user-modal.component';
import { DialogConfirmSeeUser } from './admin/pages/covid/doctor-notification/modal/confirm-mall-user-modal.component';
import { UserCovid19Component } from './admin/pages/covid/user-covid19/user-covid19.component';
import { CovidAdminAlertComponent } from './admin/pages/covid/covid-admin-alert/covid-admin-alert.component';
import { CovidDispenseMedicineListComponent } from './admin/pages/covid/covid-dispense-medicine-list/covid-dispense-medicine-list.component';
import { DialogEditVitalSign } from './admin/pages/covid/user-covid19/modal/edit-vital-sign/edit-vital-sign.component';
import { DialogAddDispenseMedicine } from './admin/pages/covid/user-covid19/modal/success-dispense-medicine/dialog-add-dispense-medicine';
import { DoctorDashboardComponent } from './admin/pages/covid/doctor-dashboard/doctor-dashboard.component';
import { DialogdialogReferUserCovid19 } from './admin/pages/covid/user-covid19/modal/refer-covid19/dialog-refer-user-covid19';
import { DialoghistoryReferUserCovid19 } from './admin/pages/covid/user-covid19/modal/history-refer-covid19/dialog-history-refer-covid19';
import { TreatedPatientsComponent } from './admin/pages/covid/treated-patients/treated-patients.component';
import { HeartRateComponent } from './admin/pages/users-profile/heart-rate/heart-rate.component';
import { SpO2Component } from './admin/pages/users-profile/sp-o2/sp-o2.component';
import { StepCountComponent } from './admin/pages/users-profile/step-count/step-count.component';
import { BodyTempComponent } from './admin/pages/users-profile/body-temp/body-temp.component';

import { ChartsModule } from 'ng2-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule } from '@agm/core';
import { ToastrModule } from 'ngx-toastr';

import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { GlobalConstants } from './common/global-constants';
import { LoginfaillComponent } from './shared/snackBar/loginfaill/loginfaill.component';
import { CaaDetailModal } from './admin/pages/covid/covid-admin-alert/modal/caa-detail-modal.component';
import { DialogdialogSuccessReferCovid19 } from './admin/pages/covid/user-covid19/modal/success-refer-covid19/dialog-success-refer-covid19';
import { TimeagoModule } from 'ngx-timeago';
import { BloodPressureComponent } from './admin/pages/users-profile/bloodpressure/bloodpressure.component';
import { FatigueComponent } from './admin/pages/users-profile/fatigue/fatigue.component';
import { StressComponent } from './admin/pages/users-profile/stress/stress.component';
import { DatePipe } from '@angular/common';
import { MomentModule } from 'angular2-moment';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { LocationComponent } from './admin/pages/users-profile/location/location.component';
import { SleepComponent } from './admin/pages/users-profile/sleep/sleep.component';
import { RespiratoryRateComponent } from './admin/pages/users-profile/respiratory-rate/respiratory-rate.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    UsersProfileComponent,
    LoginComponent,
    AdminDistrictComponent,
    AdminSubDistrictComponent,
    DialogAddAdminDistrict,
    DialogAddAdminSubDistrict,
    DialogEditProfileOperator,
    DialogEditAdminDistrictDialog,
    DialogEditAdminSubDistrictDialog,
    DialogLoginOut,
    DialogConfirmMallUser,
    DialogConfirmSeeUser,
    CaaDetailModal,
    CaseProfileComponent,
    CaseProfileAppComponent,
    DialogSuccessJob,
    DialogDetailSos,
    DialogPersonalHealth,
    DialogLogSosHistory,
    DialogCovid19Chart,
    ConnectApiErrorComponent,
    LoginfaillComponent,
    UserCovid19ListComponent,
    DoctorNotificationComponent,
    DoctorMallComponent,
    DoctorPatientComponent,
    UserCovid19Component,
    CovidAdminAlertComponent,
    CovidDispenseMedicineListComponent,
    DialogEditVitalSign,
    CaaDetailModal,
    LoginfaillComponent,
    UserCovid19ListComponent,
    DoctorNotificationComponent,
    DoctorMallComponent,
    DoctorPatientComponent,
    UserCovid19Component,
    CovidAdminAlertComponent,
    DialogAddDispenseMedicine,
    DoctorDashboardComponent,
    DialogdialogReferUserCovid19,
    DialogdialogSuccessReferCovid19,
    TreatedPatientsComponent,
    DialoghistoryReferUserCovid19,
    HeartRateComponent,
    SpO2Component,
    StepCountComponent,
    BodyTempComponent,
    LocationComponent,
    BloodPressureComponent,
    FatigueComponent,
    StressComponent,
    SleepComponent,
    RespiratoryRateComponent,
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ChartsModule,
    SharedModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    HttpClientModule,
    NgApexchartsModule,
    TimeagoModule.forRoot(),
    AgmCoreModule.forRoot({
      // apiKey: 'AIzaSyAJTz2M8gnitqIqlAnqKm8UFb-ZqlMQWHg',
      // apiKey: 'AIzaSyAROQ6yQJz5tRNWthCLu9VDn_fPBugB_gA',
      apiKey: 'AIzaSyBUlyPu-GmPDBn7I8JaYtcjr2hdool7M-E', // key IMT
      libraries: ['places']
    }),
    ToastrModule.forRoot(),
    MomentModule,
    NgIdleKeepaliveModule.forRoot(),
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [GlobalConstants, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
