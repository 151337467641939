import { Component, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from '../../../core/services/service.service';
import { io } from 'socket.io-client';
//import { Socket } from 'ngx-socket-io';
import { MapsAPILoader } from '@agm/core';
import { GlobalConstants } from 'src/app/common/global-constants';
import { SharedServiceService } from 'src/app/common/shared-service.service';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {
  imageUrl1: string = GlobalConstants.imageUrl1;
  imageUrl2: string = GlobalConstants.imageUrl2;
  noSos!: boolean;
  haveSos: boolean = true;
  progress: boolean = true;
  progressSuccess: boolean = false;
  idOperator: any;
  localStorageOperatorAdminId: any;
  // adminRole: any;
  dataSOS: any = [];
  dataConvert!: any;
  color!: string;
  // animationItem: boolean = true;
  logSos: any = [];
  roles: any[] = [];
  private socket: any;
  private geoCoder!: google.maps.Geocoder;

  dataSos_: any;
  constructor(
    private mapsAPILoader: MapsAPILoader,
    // private socket_: Socket,
    private activatedRoute: ActivatedRoute,
    private ServiceService: ServiceService,
    private sharedService: SharedServiceService,
    private router: Router,
    private ngZone: NgZone,
  ) {
    this.localStorageOperatorAdminId = localStorage.getItem("currentIdAdmin");
    // this.adminRole = localStorage.getItem("currentRole");
    this.socket = io(GlobalConstants.socket, {
      autoConnect: true,
      reconnection: true, // Enable reconnection
      reconnectionAttempts: Infinity, // Limit the number of reconnection attempts
      reconnectionDelay: 1000,
      transports: ['websocket', 'polling', 'flashsocket']
    });
    // this.socket = io('http://192.168.1.43:2022/', { transports: ['websocket', 'polling', 'flashsocket'] });
    // this.socket = io('http://183.88.230.105:2022/', { transports: ['websocket', 'polling', 'flashsocket'] });
  }

  async ngOnInit() {
    await this.getAdminRole();
    this.idOperator = this.activatedRoute.snapshot.paramMap.get('idOperatorAdmin');
    this.loadSOS(this.idOperator, true);

    // repeat with the interval of 2 seconds
    // setInterval(() => {
    //   this.setupInter();

    // }, 2000)

    this.socket.on('datamessage', async (data: any) => {
      let typeKeyword: string = this.roles.find((item: any) => item.typeAdmin == localStorage.getItem("showAdmin")).typeKeyword;
      if (data.OperatorMonitorType == typeKeyword) {
        var resDataOperator: any;
        var objectIDSOS = data.ObjectID.split(',');
        var dataSensor: any;

        await this.ServiceService.GetLogSos_(objectIDSOS[0]).then(async resSensor => {
          dataSensor = resSensor;

          this.dataSos_ = {
            OjobId: data.OJob_ID,
            OperatorId: data.Operator_ID,
            ObjectId: objectIDSOS[0],
            OjobDate: data.OJob_Date,
            OjobStatus: data.OJob_Status,
            OjobAddress: "",
            OjobImei: dataSensor[0].watch_imeicode,

          };

          this.ServiceService.UpdateCountJobOperator_(this.dataSos_).then(async resUpdateJob => {
            // console.log("---------------> : ", resUpdateJob);
            await this.ServiceService.GetIdOperatorAdminByIdOperator_(data.Operator_ID).then(dataDataOperator => {
              resDataOperator = dataDataOperator;
              if (resDataOperator[0].operatorAdminId == this.localStorageOperatorAdminId) {
                this.loadSOS(this.idOperator, false, resUpdateJob);
                // this.loadSOS(this.idOperator, false);
              }
            });
          })

        });
      }
    });

    this.sharedService.sosCaseStatus.subscribe(boom => {
      let sosCase: any = boom;
      if (boom.data) sosCase = boom.data;
      if (this.dataSOS.find((item: any) => item.idJob == sosCase.id)) {
        let index: number = this.dataSOS.findIndex((index: any) => index.idJob == sosCase.id);
        if (sosCase.status == "work") this.dataSOS[index].dataJob.ojobStatus = sosCase.status;
        else {
          this.dataSOS.splice(index, 1);
          this.loadSOS(this.idOperator, true);
        }
      }
    });
  }

  async getAdminRole() {
    await this.ServiceService.GetAdminRole_().then(res => {
      if (res.length) this.roles = res;
    }, err => {
      console.log("err -> ", err);
    });
  }

  // setupInter() {
  //   this.animationItem = !this.animationItem;
  // }

  loadSOS(idOperator: string, loadFirst: boolean, checkSosUpdate?: any) {
    this.getLogSos(idOperator, loadFirst, checkSosUpdate).then(result => {
      // console.log("dataJob Start >>>>>>>>> ", result);
      var value: any = [];
      value = result;
      this.dataSOS = value.sort((a: { idJob: number; }, b: { idJob: number; }) => a.idJob < b.idJob ? 1 : a.idJob > b.idJob ? -1 : 0);
      // console.log("- ", this.dataSOS);

      // setInterval(() => {
      this.progress = false;
      this.progressSuccess = true;
      // }, 2000);
      // console.log("ssssssss ",result);

    })
  }

  getLogSos(idOperator: string, loadFirst: boolean, checkSosUpdate: any) {
    var resLogSos: any;
    var resDevices: any;
    var resUserPhone: any;
    var resLoadSOS: any;
    return new Promise<any>((resolve, rejects) => {
      this.ServiceService.loadSOS_(idOperator).then(async dataLoadSOS => {
        resLoadSOS = dataLoadSOS;
        // console.log("res -> ", res);

        if (resLoadSOS.length > 0) {
          this.haveSos = true;
          this.noSos = false;
        } else {
          this.haveSos = false;
          this.noSos = true;
        }
        if (loadFirst) {
          // console.log("res ",resLoadSOS);
          for (let i = 0; i < resLoadSOS.length; i++) {
            var wordsjectId = resLoadSOS[i].objectId.split(',');

            await this.ServiceService.GetLogSos_(wordsjectId[0]).then(async dataLogSos => {
              resLogSos = dataLogSos;
              // console.log("dataLogSos ",dataLogSos);

              if (dataLogSos != null && dataLogSos != "") {
                // console.log("watch_imeicode ",resLogSos[0].watch_imeicode);

                await this.ServiceService.GetDeviceBySerial_(resLogSos[0].watch_imeicode).then(async dataDevice => {
                  resDevices = dataDevice;

                  if (resDevices.length != 0) {
                    // console.log(i,"55 resDevices >>> ",resDevices);
                    this.logSos.push({ idJob: resLoadSOS[i].ojobId, logSos: resLogSos[0], dataDevice: resDevices[0], dataJob: resLoadSOS[i] });
                    if (i == resLoadSOS.length - 1) {

                      resolve(this.logSos)
                    }
                  } else {
                    await this.ServiceService.GetUserPhone_(resLogSos[0].watch_imeicode).then(dataPhone => {
                      resUserPhone = dataPhone;
                      // console.log(resLogSos[0].watch_imeicode," : --------------- ",resUserPhone[0]," ** ",resLogSos[0]," ** ",resLogSos[0]," == ",resLoadSOS[i]);
                      // console.log(i,"44 resUserPhone >>> ",resUserPhone," : : ",resLogSos[0].watch_imeicode);
                      if (resUserPhone.length != 0) {
                        this.logSos.push({ idJob: resLoadSOS[i].ojobId, logSos: resLogSos[0], dataUserPhone: resUserPhone[0], dataJob: resLoadSOS[i] });
                        if (i == resLoadSOS.length - 1) {

                          resolve(this.logSos)
                        }
                      } else {
                        //ไม่มี user กับ divice ไม่ได้ pairกับ มือถือ
                        this.logSos.push({ idJob: resLoadSOS[i].ojobId, logSos: resLogSos[0], dataUserPhone: { "userName": "ไม่มีข้อมูล นาฬิกา", "status": "no" }, dataJob: resLoadSOS[i] });
                        if (i == resLoadSOS.length - 1) {

                          resolve(this.logSos)
                        }
                      }
                    }, error => {
                      console.log(error.status + ' ' + error.statusText);
                    });
                  }
                }, error => {
                  console.log(error.status + ' ' + error.statusText);
                });

              } else {
                console.log(wordsjectId[0], " resLogSos >>> ", resLogSos, " index ", i);
                console.log("ไม่มี log sos ใน mongo ", i);
              }
            }, error => {
              console.log(error.status + ' ' + error.statusText);
            });
          }
        } else {
          if (checkSosUpdate.statusUpdate == true) {
            var wordsjectId = resLoadSOS[0].objectId.split(',');
            await this.ServiceService.GetLogSos_(wordsjectId[0]).then(async dataLogSos => {
              resLogSos = dataLogSos;
              await this.ServiceService.GetDeviceBySerial_(resLogSos[0].watch_imeicode).then(async dataDevice => {
                resDevices = dataDevice;
                if (resDevices.length != 0) {
                  this.logSos.push({ idJob: resLoadSOS[0].ojobId, logSos: resLogSos[0], dataDevice: resDevices[0], dataJob: resLoadSOS[0] });
                  resolve(this.logSos);
                } else {
                  await this.ServiceService.GetUserPhone_(resLogSos[0].watch_imeicode).then(dataPhone => {
                    resUserPhone = dataPhone;
                    this.logSos.push({ idJob: resLoadSOS[0].ojobId, logSos: resLogSos[0], dataUserPhone: resUserPhone[0], dataJob: resLoadSOS[0] });
                    resolve(this.logSos);
                  }, error => {
                    console.log(error.status + ' ' + error.statusText);
                  });
                }
              }, error => {
                console.log(error.status + ' ' + error.statusText);
              });
            }, error => {
              console.log(error.status + ' ' + error.statusText);
            });
          }
        }
      });
    })
  }

  startPracticeJob(id: string, status: string, operatorId: string) {
    // console.log("id> ", id);
    var resIDOperatoAdmin: any;
    if (status != "work") {

      this.dataConvert = {
        OjobId: id,
        OperatorId: operatorId,
        ObjectId: "",
        OjobDate: "",
        OjobStatus: "work",
        OjobHeartRate: "",
        OjobTemp: "",
        OjobBattery: ""
      };
      this.ServiceService.updateStatusSosAlert_(this.dataConvert).then(dataIDOperatoAdmin => {
        resIDOperatoAdmin = dataIDOperatoAdmin;
        this.ngZone.run(() => this.router.navigateByUrl(`/caseProfile/${this.idOperator}/${id}`));

        this.socket.emit('dataMessageJobWork', { id: id, status: "work", portal: localStorage.getItem("showAdmin") });
        this.socket.emit('dataMessageJob', "sos");

      });
    } else {

      this.ngZone.run(() => this.router.navigateByUrl(`/caseProfile/${this.idOperator}/${id}`));

    }
  }
  
  formatToEightDigits(num: number): string {
    return num.toString().padStart(8, '0');
  }
}
