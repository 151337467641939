<section>

    <div class="col-12 pt-2 pb-2">
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <mat-card class="mat-elevation-z8">
                    <div class="mt-2" style="text-align: start;margin-bottom: 20px;">
                        <!-- <mat-card-title>
                            <div *ngIf="animationItem == true" class="tada">NEW <b class="">SOS</b></div>
                            <div *ngIf="animationItem == false">NEW <b class="">SOS</b></div>
                        </mat-card-title> -->
                        <mat-card-title>
                           <h2><i class="fas fa-bullhorn"></i> New<b> SOS </b> {{dataSOS.length}} case</h2>
                        </mat-card-title>
                        <hr style="margin-left: 10px; margin-right: 10px;">
                    </div>
                    <mat-card-content *ngIf="haveSos">
                        <mat-progress-bar mode="query" *ngIf="progress"></mat-progress-bar>


                        <div *ngFor="let dataSOS of dataSOS;">
                            <!-- <mat-progress-bar mode="indeterminate"></mat-progress-bar> -->
                            <div *ngIf="progressSuccess" class="mb-2">
                                <div *ngIf="dataSOS.dataDevice != null; then thenWatch else elsePhone"></div>
                                <ng-template #thenWatch>
                                    <div class="pointer hoverBtn pulse callout callout-danger mat-elevation-z8"
                                        style="margin-bottom: 8px;">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="direct-chat-infos clearfix" style="margin: auto;">

                                                    <img src="{{imageUrl2}}/{{dataSOS.dataDevice.devicePic}}"
                                                        onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                                                        class="float-left img-circle elevation-2"
                                                        style="width: 70px; height: 70px; margin-right: 10px;object-fit: cover;"
                                                        alt="User Image">

                                                    <span class="direct-chat-name"
                                                        *ngIf="dataSOS.dataDevice.deviceName == ''">-</span>
                                                    <span class="direct-chat-name"
                                                        *ngIf="dataSOS.dataDevice.deviceName != ''">{{dataSOS.dataDevice.deviceName}}</span>
                                                    <h3 style="margin: 0;padding: 0px;">
                                                        <b>From</b> :
                                                        <span *ngIf="dataSOS.logSos.sos_type == 'sos'"><span>
                                                                <mat-icon>watch</mat-icon> <b>SOS From Watch</b>
                                                            </span></span>
                                                        <span *ngIf="dataSOS.logSos.sos_type == 'sos-phone'"><span>
                                                                <mat-icon>phone_android</mat-icon> <b>SOS From Phone</b>
                                                            </span></span>
                                                        <span *ngIf="dataSOS.logSos.sos_type == 'temp-fail'"><span>
                                                                <mat-icon>watch</mat-icon> <b>Temp Fail</b>
                                                            </span></span>
                                                        <span *ngIf="dataSOS.logSos.sos_type == 'heart-fail'"><span>
                                                                <mat-icon>watch</mat-icon> <b>Heartrate Fail</b>
                                                            </span></span>
                                                        <span *ngIf="dataSOS.logSos.sos_type == 'fall-detect'"><span>
                                                                <mat-icon>watch</mat-icon> <b>Fall</b>
                                                                <span *ngIf="dataSOS.dataJob.fallMistake >= 3" class="text-danger ml-3" style="font-size: 14px;">SOS Fall : Yes ({{dataSOS.dataJob.fallMistake}})</span>
                                                            </span></span>

                                                            <span *ngIf="dataSOS.logSos.sos_type == 'heartrate-detect'"><span>
                                                                <mat-icon>watch</mat-icon> <b>Heartrate Detect</b>
                                                            </span></span>
                                                            <span *ngIf="dataSOS.logSos.sos_type == 'spo2-detect'"><span>
                                                                <mat-icon>watch</mat-icon> <b>Spo2 Detect</b>
                                                            </span></span>
                                                            <span *ngIf="dataSOS.logSos.sos_type == 'bodytemp-detect'"><span>
                                                                <mat-icon>watch</mat-icon> <b>Bodytemp Detect</b>
                                                            </span></span>
                                                            <span *ngIf="dataSOS.logSos.sos_type == 'bloodpressure-detect'"><span>
                                                                <mat-icon>watch</mat-icon> <b>Bloodpressure Detect</b>
                                                            </span></span>
                                                            <span *ngIf="dataSOS.logSos.sos_type == 'pulsepressure-detect'"><span>
                                                                <mat-icon>watch</mat-icon> <b>Pulsepressure Detect</b>
                                                            </span></span>
                                                            <span *ngIf="dataSOS.logSos.sos_type == 'spo2-low'"><span>
                                                                <mat-icon>watch</mat-icon> <b>Low SpO2</b>
                                                            </span></span>
                                                            <span *ngIf="dataSOS.logSos.sos_type == 'spo2-low-avg-hour'"><span>
                                                                <mat-icon>watch</mat-icon> <b>SpO2 Avg Hour Detection</b>
                                                            </span></span>
                                                            <span *ngIf="dataSOS.logSos.sos_type == 'spo2-low-avg-day'"><span>
                                                                <mat-icon>watch</mat-icon> <b>SpO2 Avg Day Detection</b>
                                                            </span></span>
                                                            <span *ngIf="dataSOS.logSos.sos_type.includes('ai-')"><span>
                                                                <mat-icon>watch</mat-icon> <b>AI Health Alerts</b>
                                                            </span></span>
                                                            <span *ngIf="dataSOS.logSos.sos_type == 'bodytemp-lostdata'"><span>
                                                                <mat-icon>watch</mat-icon> <b>Body Temp Lost Data</b>
                                                            </span></span>
                                                            <span *ngIf="dataSOS.logSos.sos_type == 'spo2-lostdata'"><span>
                                                                <mat-icon>watch</mat-icon> <b>SpO2 Lost Data</b>
                                                            </span></span>
                                                            <span *ngIf="dataSOS.logSos.sos_type == 'heartrate-lostdata'"><span>
                                                                <mat-icon>watch</mat-icon> <b>Heart Rate Lost Data</b>
                                                            </span></span>
                                                            <span *ngIf="dataSOS.logSos.sos_type == 'bloodpressure-lostdata'"><span>
                                                                <mat-icon>watch</mat-icon> <b>Blood Pressure Lost Data</b>
                                                            </span></span>

                                                    </h3>
                                                    <h3 style="margin: 0;padding: 0px;">
                                                        <b>Case ID</b> :
                                                        <span class="ml-1">{{formatToEightDigits(dataSOS.dataJob.ojobId)}}</span>
                                                    </h3>
                                                    <div class="row">
                                                        <p style="padding-right: 5px;"><b>Location : </b> Lat:
                                                            <b>{{dataSOS.logSos.lat}}</b>
                                                            Long: <b>{{dataSOS.logSos.lng}}</b>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <span class="right badge badge-danger float-right"
                                                    style="font-size:12px"><i class="fas fa-exclamation-triangle"></i>
                                                    {{dataSOS.logSos.time_log | date :'short'}}</span>
                                                <br>
                                                <div class="example-button-row float-right" style="text-align: end;"
                                                    *ngIf="dataSOS.dataJob.ojobStatus != 'work'">
                                                    <button mat-raised-button color="primary"
                                                        (click)="startPracticeJob(dataSOS.dataJob.ojobId,dataSOS.dataJob.ojobStatus,dataSOS.dataJob.operatorId)">Confirm</button>
                                                </div>
                                                <div class="example-button-row float-right" style="text-align: end;"
                                                    *ngIf="dataSOS.dataJob.ojobStatus == 'work'">
                                                    <button mat-raised-button color="accent"
                                                        (click)="startPracticeJob(dataSOS.dataJob.ojobId,dataSOS.dataJob.ojobStatus,dataSOS.dataJob.operatorId)">Working</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template #elsePhone>
                                    <div class="pointer hoverBtn pulse callout callout-danger mat-elevation-z8"
                                        style="margin-bottom: 8px;">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="direct-chat-infos clearfix" style="margin: auto;">
                                                    <img src="{{imageUrl2}}/{{dataSOS.dataUserPhone.userPic}}"
                                                        onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                                                        class="float-left img-circle elevation-2"
                                                        style="width: 70px; height: 70px; margin-right: 10px;object-fit: cover;"
                                                        alt="User Image">
                                                    <span class="direct-chat-name"
                                                        *ngIf="dataSOS.dataUserPhone.userName == ''">-</span>
                                                    <span class="direct-chat-name"
                                                        *ngIf="dataSOS.dataUserPhone.userName != ''">{{dataSOS.dataUserPhone.userName}}</span>
                                                    <h3 style="margin: 0;padding: 0px;">
                                                        <b>From</b> :
                                                        <span *ngIf="dataSOS.logSos.sos_type == 'sos'"><span>
                                                                <mat-icon>watch</mat-icon> <b>SOS From Watch</b>
                                                            </span></span>
                                                        <span *ngIf="dataSOS.logSos.sos_type == 'sos-phone'"><span>
                                                                <mat-icon>phone_android</mat-icon> <b>SOS From Phone</b>
                                                            </span></span>
                                                        <span *ngIf="dataSOS.logSos.sos_type == 'temp-fail'"><span>
                                                                <mat-icon>watch</mat-icon> <b>Temp Fail</b>
                                                            </span></span>
                                                        <span *ngIf="dataSOS.logSos.sos_type == 'heart-fail'"><span>
                                                                <mat-icon>watch</mat-icon> <b>Heartrate Fail</b>
                                                            </span></span>
                                                        <span *ngIf="dataSOS.logSos.sos_type == 'fall-detect'"><span>
                                                                <mat-icon>watch</mat-icon> <b>Fall</b>
                                                            </span></span>

                                                        <span *ngIf="dataSOS.logSos.sos_type == 'heartrate-detect'"><span>
                                                            <mat-icon>watch</mat-icon> <b>Heartrate Detect</b>
                                                        </span></span>
                                                        <span *ngIf="dataSOS.logSos.sos_type == 'spo2-detect'"><span>
                                                            <mat-icon>watch</mat-icon> <b>Spo2 Detect</b>
                                                        </span></span>
                                                        <span *ngIf="dataSOS.logSos.sos_type == 'bodytemp-detect'"><span>
                                                            <mat-icon>watch</mat-icon> <b>Bodytemp Detect</b>
                                                        </span></span>
                                                        <span *ngIf="dataSOS.logSos.sos_type == 'bloodpressure-detect'"><span>
                                                            <mat-icon>watch</mat-icon> <b>Bloodpressure Detect</b>
                                                        </span></span>
                                                        <span *ngIf="dataSOS.logSos.sos_type == 'pulsepressure-detect'"><span>
                                                            <mat-icon>watch</mat-icon> <b>Pulsepressure Detect</b>
                                                        </span></span>
                                                        <span *ngIf="dataSOS.logSos.sos_type == 'spo2-low'"><span>
                                                            <mat-icon>watch</mat-icon> <b>Low SpO2</b>
                                                        </span></span>
                                                        <span *ngIf="dataSOS.logSos.sos_type == 'spo2-low-avg-hour'"><span>
                                                            <mat-icon>watch</mat-icon> <b>SpO2 Avg Hour Detection</b>
                                                        </span></span>
                                                        <span *ngIf="dataSOS.logSos.sos_type == 'spo2-low-avg-day'"><span>
                                                            <mat-icon>watch</mat-icon> <b>SpO2 Avg Day Detection</b>
                                                        </span></span>
                                                        <span *ngIf="dataSOS.logSos.sos_type.includes('ai-')"><span>
                                                            <mat-icon>watch</mat-icon> <b>AI Health Alerts</b>
                                                        </span></span>
                                                        <span *ngIf="dataSOS.logSos.sos_type == 'bodytemp-lostdata'"><span>
                                                            <mat-icon>watch</mat-icon> <b>Body Temp Lost Data</b>
                                                        </span></span>
                                                        <span *ngIf="dataSOS.logSos.sos_type == 'spo2-lostdata'"><span>
                                                            <mat-icon>watch</mat-icon> <b>SpO2 Lost Data</b>
                                                        </span></span>
                                                        <span *ngIf="dataSOS.logSos.sos_type == 'heartrate-lostdata'"><span>
                                                            <mat-icon>watch</mat-icon> <b>Heart Rate Lost Data</b>
                                                        </span></span>
                                                        <span *ngIf="dataSOS.logSos.sos_type == 'bloodpressure-lostdata'"><span>
                                                            <mat-icon>watch</mat-icon> <b>Blood Pressure Lost Data</b>
                                                        </span></span>
                                                    </h3>
                                                    <h3 style="margin: 0;padding: 0px;">
                                                        <b>Case ID</b> :
                                                        <span class="ml-1">{{formatToEightDigits(dataSOS.dataJob.ojobId)}}</span>
                                                    </h3>
                                                    <div class="row">
                                                        <p style="padding-right: 5px;"><b>Location : </b> Lat:
                                                            <b>{{dataSOS.logSos.lat}}</b>
                                                            Long: <b>{{dataSOS.logSos.lng}}</b>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6" *ngIf="dataSOS.dataUserPhone.status != 'no'">
                                                <span class="right badge badge-danger float-right"
                                                    style="font-size:12px"><i class="fas fa-exclamation-triangle"></i>
                                                    {{dataSOS.logSos.time_log | date :'short'}}</span>
                                                <br>
                                                <div class="example-button-row float-right" style="text-align: end;"
                                                    *ngIf="dataSOS.dataJob.ojobStatus != 'work'">
                                                    <button mat-raised-button color="primary"
                                                        (click)="startPracticeJob(dataSOS.dataJob.ojobId,dataSOS.dataJob.ojobStatus,dataSOS.dataJob.operatorId)">Confirm</button>
                                                </div>
                                                <div class="example-button-row float-right" style="text-align: end;"
                                                    *ngIf="dataSOS.dataJob.ojobStatus == 'work'">
                                                    <button mat-raised-button color="accent"
                                                        (click)="startPracticeJob(dataSOS.dataJob.ojobId,dataSOS.dataJob.ojobStatus,dataSOS.dataJob.operatorId)">Working</button>
                                                </div>
                                            </div>
                                            <div class="col-6" *ngIf="dataSOS.dataUserPhone.status == 'no'" style="text-align: end;margin-top: auto;">

                                                    <strong style="color: red;"> *นาฬิกาไม่ได้เชื่อมต่อกับมือถือ</strong>
                                                       <p> ไม่สามารถปิดเคสได้</p>
                                            </div>
                                        </div>
                                    </div>

                                </ng-template>
                            </div>
                        </div>
                    </mat-card-content>
                    <mat-card-content *ngIf="noSos">
                        <div class="mb-2">
                            <div style="display: flex;">
                                <div class="container rubberBand"
                                    style="color: #bdbbb7;margin-top: 100px;margin-bottom: 100px;text-align: center;">
                                    <i class="fas fa-bell-slash" style="font-size: 50px;"></i>
                                    <p>No Notification.</p>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-md-1"></div>
        </div>
    </div>
</section>
