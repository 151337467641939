import { OnInit, Component, ViewChild, NgZone, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { ServiceService } from '../../../core/services/service.service';
import { MatDialog } from '@angular/material/dialog';
import { LOCALE_ID, Inject } from '@angular/core';
import { DialogLogSosHistory } from './modal/logsos/log-sos.component';
import { SharedServiceService } from 'src/app/common/shared-service.service';
import { GlobalConstants } from 'src/app/common/global-constants';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-users-profile',
  templateUrl: './users-profile.component.html',
  styleUrls: ['./users-profile.component.scss']
})
export class UsersProfileComponent implements OnInit {
  imageUrl1: string = GlobalConstants.imageUrl1;
  imageUrl2: string = GlobalConstants.imageUrl2;
  imageUrl3: string = GlobalConstants.imageUrl3;
  device!: boolean;
  phone!: boolean;
  getIdUser: any;
  getTypeDevice: any;
  dataUser: any;
  dataDevice: any;
  dataPhoneConnectWatch: any;
  dataWatchConnectPhone: any;
  dataSOSHistory: any;
  bmi: number = 0;
  imeicode: any;
  tabIndex = 0;
  showStatus: any;
  hospital: string = "";
  role: string | null;
  package: any;
  orderHistory: any[] = [];
  orderHistoryObs: Observable<any>;
  orderHistorySource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) orderHistoryPaginator: MatPaginator;

  constructor(
    private sharedServiceService: SharedServiceService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private ServiceService: ServiceService,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.getTypeDevice = this.activatedRoute.snapshot.paramMap.get('typeDevice');
    this.getIdUser = this.activatedRoute.snapshot.paramMap.get('idUser');
    this.role = localStorage.getItem("currentRole");
    console.log(""+ this.getTypeDevice);
    
  }

  async ngOnInit(): Promise<void> {
 
    setTimeout(()=>
    {
      console.log(this.dataDevice.watch);
      
    },5000)
    
    if (this.getTypeDevice == "phone") {
      var resPhone: any;
      var resBmi: any;
      await this.ServiceService.GetUserPhone_(this.getIdUser).then(dataPhone => {
        resPhone = dataPhone;
        // console.log("res GetUserPhone >> ", resPhone);
        this.dataUser = resPhone;
        this.device = false;
        this.phone = true;

     
        
        this.loadPhoneConnectWatch(resPhone[0].userId);
        // this.loadDataTable(this.getIdUser);
        // console.log(resPhone[0]);

        resBmi = (resPhone[0].userWeight / ((resPhone[0].userHeight / 100) * (resPhone[0].userHeight / 100)));
        this.bmi = resBmi.toString().substring(0, 5);

        this.ServiceService.GetPacKageUserNoAuthV1_(this.dataUser[0].userId).then(res => {
          if (res.status) this.package = res.data;
        }, err => {
          console.log("err -> ", err);
        });
        this.ServiceService.GetUserPackageActivateByserID_(this.dataUser[0].userId).then(res => {
          if (res.status && res.data.length) {
            this.orderHistory = res.data;
            this.orderHistory.sort((a: any, b: any) => new Date(b.dataPackageUseToUser.genaratedDate).getTime() - new Date(a.dataPackageUseToUser.genaratedDate).getTime());
            this.orderHistory = this.orderHistory.map((item: any, index: number) => ({...item, index: index + 1}));
            setTimeout(() => {
              this.orderHistorySource = new MatTableDataSource(this.orderHistory);
              this.orderHistorySource.paginator = this.orderHistoryPaginator;
              this.orderHistoryObs = this.orderHistorySource.connect();
            }, 1);
          }
        }, err => {
          console.log("err -> ", err);
        });
      })
      this.loadJobSOS(this.getIdUser);
    }
    else if (this.getTypeDevice == "hw-watch") {
      var resPhone: any;
      var resBmi: any;
      await this.ServiceService.GetUserPhone_(this.getIdUser).then(dataPhone => {
        resPhone = dataPhone;
        // console.log("res GetUserPhone >> ", resPhone);
        this.dataUser = resPhone;
        this.device = false;
        this.phone = true;
       
        this.loadPhoneConnectWatch(resPhone[0].userId);
        // this.loadDataTable(this.getIdUser);
        // console.log(resPhone[0]);

        resBmi = (resPhone[0].userWeight / ((resPhone[0].userHeight / 100) * (resPhone[0].userHeight / 100)));
        this.bmi = resBmi.toString().substring(0, 5);

        this.ServiceService.GetPacKageUserNoAuthV1_(resPhone[0].userId).then(res => {
          if (res.status) this.package = res.data;
        }, err => {
          console.log("err -> ", err);
        });
      })
      this.loadJobSOS(this.getIdUser);
      this.sharedServiceService.selectIDDeviceCompUserprofile(this.getIdUser);

    }
    else {
      
      var resWatch: any;
      var dataDeviceSensor: any;
      var dataSensor: any = [];
      await this.ServiceService.GetDeviceWatch_(this.getIdUser).then(async data => {
        resWatch = data;
        // console.log("resWatch : ", resWatch);

        this.getStatus(resWatch[0].deviceSerial);
        this.device = true;
        this.phone = false;
        // console.log("res --- >> ", resWatch);
        // console.log("res --- >> ", res[0].deviceSerial);
        await this.ServiceService.GetSensorsOld_(resWatch[0].deviceSerial).then(resSensor => {
          dataDeviceSensor = resSensor;
          // console.log("dataDeviceSensor ", dataDeviceSensor);

          if (resWatch[0].deviceSerial.length <= 16 && resWatch[0].deviceType == "B10" || resWatch[0].deviceType == "BLUETOOTH" || resWatch[0].deviceType == "E66") {
            
            console.log("cn ", resWatch[0]);
            this.getTypeDevice = resWatch[0].deviceType
          
            if (resWatch[0].swcnOnlineStatus == "Offline" || resWatch[0].swcnOnlineStatus == null) {
              //offilne
              dataSensor.push({ dataDevice: resWatch[0], dataDeviceSensor: dataDeviceSensor.lastLog, status: "offline", watch: resWatch[0].deviceType });
            } else {
              //onilne
              dataSensor.push({ dataDevice: resWatch[0], dataDeviceSensor: dataDeviceSensor.lastLog, status: "online", watch: resWatch[0].deviceType });
            }
          } else {
            // console.log("hw ", resWatch[i].deviceSerial);
            var currentDate = new Date(dataDeviceSensor.lastLog.location.time);
            var today = new Date();
            var diffMs = (today.getTime() - currentDate.getTime()); // milliseconds between now & Christmas
            var diffDays = Math.floor(diffMs / 86400000); // days
            var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
            var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
            // console.log(i," : ",diffDays ," day ",diffHrs ," h ",diffMins," m ",dataDeviceSensor.lastLog.location.time," = ",resWatch[i].deviceSerial);
            if (diffDays >= 1) {
              //offilne
              dataSensor.push({ dataDevice: resWatch[0], dataDeviceSensor: dataDeviceSensor.lastLog, status: "offline", watch: "hw", time: `${diffDays}day ${diffHrs}hr ${diffMins}min` });
            } else {
              if (diffHrs >= 1) {
                //offilne
                dataSensor.push({ dataDevice: resWatch[0], dataDeviceSensor: dataDeviceSensor.lastLog, status: "offline", watch: "hw", time: `${diffDays}day ${diffHrs}hr ${diffMins}min` });
              } else {
                if (diffMins >= 12) {
                  //offilne
                  dataSensor.push({ dataDevice: resWatch[0], dataDeviceSensor: dataDeviceSensor.lastLog, status: "offline", watch: "hw", time: `${diffDays}day ${diffHrs}hr ${diffMins}min` });
                } else {
                  //onilne
                  dataSensor.push({ dataDevice: resWatch[0], dataDeviceSensor: dataDeviceSensor.lastLog, status: "online", watch: "hw", time: null });
                }
              }
            }
          }
        });

        this.dataDevice = dataSensor[0];

        this.imeicode = this.dataDevice.dataDevice.deviceSerial;
        this.getTypeDevice = this.dataDevice.dataDevice.deviceType;
        if( this.getTypeDevice ==  "B10"){
          this.getTypeDevice = "MW10" 
          }
        this.loadWatchConnectPhone(resWatch[0].deviceUserId);

        this.sharedServiceService.selectIDDeviceCompUserprofile(resWatch[0].deviceSerial);

        this.loadJobSOS(dataSensor[0].dataDevice.deviceSerial);

      });
    }
    this.getHospital(this.getIdUser);
  }

  async loadPhoneConnectWatch(id: string) {
    var resDeviceConnectPhone: any;
    var dataDeviceSensor: any = [];
    var dataSensor: any = [];
    await this.ServiceService.GetPhoneConnectWatch_(id).then(async dataDeviceConnectPhone => {
      resDeviceConnectPhone = dataDeviceConnectPhone;
      // console.log("resDeviceConnectPhone ",resDeviceConnectPhone);

      for (let i = 0; i < resDeviceConnectPhone.length; i++) {
        await this.ServiceService.GetSensorsOld_(resDeviceConnectPhone[i].deviceSerial).then(resDeviceSensor => {
          dataDeviceSensor = resDeviceSensor;
          // console.log("dataDeviceSensor : ",dataDeviceSensor);
          // console.log(resDeviceConnectPhone[i].deviceType);

          if (resDeviceConnectPhone[i].deviceSerial.length <= 16 && resDeviceConnectPhone[i].deviceType == "B10" || resDeviceConnectPhone[i].deviceType == "BLUETOOTH" || resDeviceConnectPhone[i].deviceType == "E66") {
            // console.log("cn ", dataDevices[i].deviceSerial);
            if (resDeviceConnectPhone[i].swcnOnlineStatus == "Offline" || resDeviceConnectPhone[i].swcnOnlineStatus == null) {
              //offilne
              dataSensor.push({ dataDevice: resDeviceConnectPhone[i], dataDeviceSensor: dataDeviceSensor.lastLog, status: "offline", watch: resDeviceConnectPhone[i].deviceType });
            } else {
              //onilne
              dataSensor.push({ dataDevice: resDeviceConnectPhone[i], dataDeviceSensor: dataDeviceSensor.lastLog, status: "online", watch: resDeviceConnectPhone[i].deviceType });
            }
          } else {
            // console.log("hw ", dataDevices[i].deviceSerial);
            var currentDate = new Date(dataDeviceSensor.lastLog.location.time);
            var today = new Date();
            var diffMs = (today.getTime() - currentDate.getTime()); // milliseconds between now & Christmas
            var diffDays = Math.floor(diffMs / 86400000); // days
            var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
            var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
            // console.log(i," : ",diffDays ," day ",diffHrs ," h ",diffMins," m ",dataDeviceSensor.lastLog.location.time," = ",dataDevices[i].deviceSerial);
            if (diffDays >= 1) {
              //offilne
              dataSensor.push({ dataDevice: resDeviceConnectPhone[i], dataDeviceSensor: dataDeviceSensor.lastLog, status: "offline", watch: "hw", time: `${diffDays}day ${diffHrs}hr ${diffMins}min` });
            } else {
              if (diffHrs >= 1) {
                //offilne
                dataSensor.push({ dataDevice: resDeviceConnectPhone[i], dataDeviceSensor: dataDeviceSensor.lastLog, status: "offline", watch: "hw", time: `${diffDays}day ${diffHrs}hr ${diffMins}min` });
              } else {
                if (diffMins >= 12) {
                  //offilne
                  dataSensor.push({ dataDevice: resDeviceConnectPhone[i], dataDeviceSensor: dataDeviceSensor.lastLog, status: "offline", watch: "hw", time: `${diffDays}day ${diffHrs}hr ${diffMins}min` });
                } else {
                  //onilne
                  dataSensor.push({ dataDevice: resDeviceConnectPhone[i], dataDeviceSensor: dataDeviceSensor.lastLog, status: "online", watch: "hw", time: null });
                }
              }
            }
          }
        });
        if (i == resDeviceConnectPhone.length - 1) {
          // console.log("dataSensor ",dataSensor);
          this.dataPhoneConnectWatch = dataSensor;
        }
      }

    })
  }

  async loadWatchConnectPhone(id: string) {
    var resWatchConnectPhone: any;
    await this.ServiceService.GetWatchConnectPhone_(id).then(dataWatchConnectPhone => {
      resWatchConnectPhone = dataWatchConnectPhone;
      this.dataWatchConnectPhone = resWatchConnectPhone;
    })
  }

  loadJobSOS(idUser: string) {
    this.ServiceService.GetJobByIdUser_(idUser).then(resSOSHistory => {
      // console.log("----- ", resSOSHistory);
      this.dataSOSHistory = resSOSHistory;
    });
  }

  openDialogLogSosHistory(dataSos: any): void {
    const dialogRef = this.dialog.open(DialogLogSosHistory, {
      width: '1500px',
      data: { dataSos }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }

  changeTab(event:any){
    // console.log(event.index)
    this.tabIndex = event.index;
  }

  getHospital(UserId: string) {
    this.ServiceService.GetUsersAndDevice_("Rux").then((res) => {
      if (res.length) {
        this.hospital = res.find((item: any) => item.deviceId == UserId || item.userId == UserId).pAffHospital;
      }
    }, (err) => {
      console.log("Error -> ", err);
    });
  }

  getPage(Event: boolean) {
    if (Event) {
      this.tabIndex = 0;
    }
  }

  getStatus(Imei: string) {
    this.ServiceService.getSmartwatchStatusInfoByImei_(Imei).then(res => {
      if (res && res.deviceStatusInfo) this.showStatus = res.deviceStatusInfo;
    }, err => {
      console.log("err -> ", err);
    });
  }

  aiCharts: boolean = false

  callAICharts() {
    this.aiCharts = true
  }

  turnModal(Event: boolean) {
    this.aiCharts = Event
  }
}

