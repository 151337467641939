import { color } from '@amcharts/amcharts4/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { first } from 'rxjs/operators';
import { ServiceService } from 'src/app/core/services/service.service';

@Component({
  selector: 'app-ai-charts',
  templateUrl: './ai-charts.component.html',
  styleUrls: ['./ai-charts.component.scss']
})
export class AiChartsComponent implements OnInit {
  @Input() imei: string | null = null
  @Output() md = new EventEmitter<boolean>()
  charts: any = null
  loading: boolean = true
  public chartZero: any;
  public chartI: any;
  public chartII: any;
  public chartIII: any;
  public chartIV: any;

  constructor(
    private service: ServiceService
  ) { }

  async ngOnInit() {
    await this.getAICharts()
    this.loading = false
  }

  async getAICharts() {
    try {
      let startDate: string = new Date(new Date().getTime() + (3600000 * 7) - (3600000 * 24 * 6)).toISOString().split('T')[0]
      let endDate: string = new Date(new Date().getTime() + (3600000 * 7)).toISOString().split('T')[0]
      let postData: any = {
        "imei": this.imei,
        "startDate": startDate,
        "endDate": endDate
      }
      const res = await this.service.GetChartAnalysis(postData).pipe(first()).toPromise()
      if (res.status) {
        this.charts = res.charts
        await this.generateChartZero()
        await this.generateChartI()
        await this.generateChartII()
        await this.generateChartIII()
        await this.generateChartIV()
      }
    } catch (err) {
      console.log('GetChartAnalysis', err)
    }
  }

  async generateChartZero() {
    let total: any[] = [
      { category: 'SpO2', total: 0, average: 0, last: 0, diff: 0, percent: 0 },
      { category: 'Heart Rate', total: 0, average: 0, last: 0, diff: 0, percent: 0 },
      { category: 'BP Systolic', total: 0, average: 0, last: 0, diff: 0, percent: 0 },
      { category: 'BP Diastolic', total: 0, average: 0, last: 0, diff: 0, percent: 0 },
      { category: 'Stress', total: 0, average: 0, last: 0, diff: 0, percent: 0 },
      { category: 'Fatique', total: 0, average: 0, last: 0, diff: 0, percent: 0 },
      { category: 'Temperature', total: 0, average: 0, last: 0, diff: 0, percent: 0 },
      { category: 'Sleep', total: 0, average: 0, last: 0, diff: 0, percent: 0 },
      { category: 'Light Sleep', total: 0, average: 0, last: 0, diff: 0, percent: 0 },
      { category: 'Deep Sleep', total: 0, average: 0, last: 0, diff: 0, percent: 0 }
    ]
    if (this.charts.radarChartData.length) {
      let data: any[] = [ ...this.charts.radarChartData ]
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].values.length; j++) {
          total[j].total += data[i].values[j]
          if (i >= data.length - 1) {
            total[j].last = data[i].values[j]
          }
        }
      }
      total.forEach((item, index) => {
        total[index].average = item.total / data.length
        total[index].diff = (total[index].average - item.last >= 0 ? total[index].average - item.last : (total[index].average - item.last) * -1)
        total[index].percent = (total[index].diff / total[index].average) * 100
        total[index].percent = total[index].percent * (total[index].last >= total[index].average ? 1 : -1)
      })
    }
    let categories: string[] = [ ...total.map(item => item.category) ]
    let averages: number[] = [ ...total.map(item => item.average).slice(0, 7) ]
    let latests: number[] = [ ...total.map(item => item.last).slice(0, 7) ]
    let percents: number[] = [ ...total.map(item => item.percent).slice(0, 7) ]
    let avMax: number = Math.round(Math.max(...averages))
    let lastMax: number = Math.round(Math.max(...latests))
    let pMax: number = Math.round(Math.max(...percents))
    let pMin: number = Math.round(Math.min(...percents)) - 1
    let averagesS: number[] = [ ...total.map(item => item.average).slice(7, 10) ]
    let latestsS: number[] = [ ...total.map(item => item.last).slice(7, 10) ]
    let percentsS: number[] = [ ...total.map(item => item.percent).slice(7, 10) ]
    let avSMax: number = Math.round(Math.max(...averagesS))
    let lastSMax: number = Math.round(Math.max(...latestsS))
    let pSMax: number = Math.round(Math.max(...percentsS))
    let pSMin: number = Math.round(Math.min(...percentsS)) - 1
    this.chartZero = {
      series: [
        {
          name: "ค่าเฉลี่ย (Average) - Main",
          type: "column",
          data: [ ...averages, null, null, null ],
        },
        {
          name: "ค่าล่าสุด (Latest) - Main",
          type: "column",
          data: [ ...latests, null, null, null ],
        },
        {
          name: "การเปลี่ยนแปลง (%) - Main",
          type: "line",
          data: [ ...percents, null, null, null ],
        },
        {
          name: "ค่าเฉลี่ย (Average) - Sleep",
          type: "column",
          data: [ null, null, null, null, null, null, null, ...averagesS ],
        },
        {
          name: "ค่าล่าสุด (Latest) - Sleep",
          type: "column",
          data: [ null, null, null, null, null, null, null, ...latestsS ],
        },
        {
          name: "การเปลี่ยนแปลง (%) - Sleep",
          type: "line",
          data: [ null, null, null, null, null, null, null, ...percentsS ],
        },
      ],
      chart: {
        type: "line",
        height: 420,
        stacked: false,
      },
      stroke: {
        curve: "smooth",
        width: [0, 0, 3, 0, 0, 3],
      },
      colors: ["#3f51b5", "#91a7ff", "#42bd41", "#673ab7", "#ff5722", "#e91e63"],
      xaxis: {
        categories: categories,
      },
      yaxis: [
        {
          title: {
            text: "ค่าตัวชี้วัด (Main)",
          },
          labels: {
            formatter: (value: number) => Math.round(value),
          },
          min: 0,
          max: avMax,
        },
        {
          show: false,
          title: {
            text: "ค่าตัวชี้วัด (Main)",
          },
          labels: {
            formatter: (value: number) => Math.round(value),
          },
          min: 0,
          max: lastMax,
        },
        {
          title: {
            text: "การเปลี่ยนแปลง (%) Main",
          },
          labels: {
            formatter: (value: number) => Math.round(value),
          },
          min: pMin,
          max: pMax,
        },
        {
          opposite: true,
          title: {
            text: "ค่าตัวชี้วัด (Sleep)",
          },
          labels: {
            formatter: (value: number) => Math.round(value),
          },
          min: 0,
          max: avSMax,
        },
        {
          show: false,
          opposite: true,
          title: {
            text: "ค่าตัวชี้วัด (Sleep)",
          },
          labels: {
            formatter: (value: number) => Math.round(value),
          },
          min: 0,
          max: lastSMax,
        },
        {
          opposite: true,
          title: {
            text: "การเปลี่ยนแปลง (%) Sleep",
          },
          labels: {
            formatter: (value: number) => Math.round(value),
          },
          min: pSMin,
          max: pSMax,
        },
      ],
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: (value: number | null) =>
            value ?
            Number.isInteger(value) ? value.toString() : value.toFixed(2)
            :
            null
        },
      },
      legend: {
        position: "top",
      },
    }
  }

  async generateChartI() {
    let hrData: number[] = []
    let sData: number[] = []
    let categories: string[] = []
    if (this.charts.recoveryTrend.length) {
      let chartIData: any[] = [ ...this.charts.recoveryTrend ]
      chartIData.forEach(item => {
        hrData.push(item.heart_rate)
        sData.push(item.stress)
        categories.push(this.formatDateISO(item.timestamp))
      })
    }
    this.chartI = {
      series: [
        {
          name: 'อัตราการเต้นของหัวใจ (Heart Rate)',
          data: hrData,
          color: '#3f51b5'
        },
        {
          name: 'ค่าความเครียด (Stress)',
          data: sData,
          color: '#e51c23'
        }
      ],
      chart: {
        type: 'line',
        height: 350
      },
      stroke: {
      },
      title: {
        text: "การเปรียบเทียบค่าเฉลี่ยและค่าล่าสุด พร้อมแสดงการเปลี่ยนแปลง (%)",
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        labels: {
          formatter: function (value: number) {
            return Number.isInteger(value) ? value.toString() : value.toFixed(2);
          }
        }
      },
      legend: {
        position: 'top'
      },
      tooltip: {
        y: {
          formatter: function (value: number) {
            return Number.isInteger(value) ? value.toString() : value.toFixed(2);
          }
        }
      }
    }
  }

  async generateChartII() {
    let fData: number[] = []
    let categories: string[] = []
    if (this.charts.anomalyFrequency.length) {
      let chartIIData: any[] = [ ...this.charts.anomalyFrequency ]
      chartIIData.forEach(item => {
        fData.push(item.count)
        categories.push(item.category)
      })
    }
    this.chartII = {
      series: [
        {
          name: 'ความถี่ (Frequency)',
          data: fData,
          color: '#ffb300'
        }
      ],
      chart: {
        type: 'bar',
        height: 350
      },
      stroke: {
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        labels: {
          formatter: function (value: number) {
            return Number.isInteger(value) ? value.toString() : value.toFixed(2);
          }
        }
      },
      legend: {
        position: 'top'
      },
      tooltip: {
        y: {
          formatter: function (value: number) {
            return Number.isInteger(value) ? value.toString() : value.toFixed(2);
          }
        }
      }
    }
  }

  async generateChartIII() {
    let sData: number[] = []
    let categories: string[] = []
    if (this.charts.anomalyTrend.length) {
      let chartIIIData: any[] = [ ...this.charts.anomalyTrend ]
      chartIIIData.forEach(item => {
        sData.push(item.score)
        categories.push(this.formatDateISO(item.timestamp))
      })
    }
    this.chartIII = {
      series: [
        {
          name: 'ค่าความผิดปกติ (Anomaly Score)',
          data: sData,
          color: '#7b1fa2'
        }
      ],
      chart: {
        type: 'line',
        height: 350
      },
      stroke: {
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        labels: {
          formatter: function (value: number) {
            return Number.isInteger(value) ? value.toString() : value.toFixed(2);
          }
        }
      },
      legend: {
        position: 'top'
      },
      tooltip: {
        y: {
          formatter: function (value: number) {
            return Number.isInteger(value) ? value.toString() : value.toFixed(2);
          }
        }
      }
    }
  }

  async generateChartIV() {
    let data: any[] = []
    if (this.charts.scatterPlot.length) {
      let chartIVData: any[] = [ ...this.charts.scatterPlot ]
      chartIVData.forEach(item => {
        data.push({ x: item.heart_rate, y: item.stress })
      })
    }
    this.chartIV = {
      series: [
        {
          name: 'ความเครียดเทียบกับอัตราการเต้นของหัวใจ (Stress vs Heart Rate)',
          data: data
        }
      ],
      chart: {
        type: 'scatter',
        height: 350
      },
      colors: ['#0a7e07'],
      stroke: {
      },
      xaxis: {
        title: { text: 'อัตราการเต้นของหัวใจ (Heart Rate)' },
        type: 'numeric',
        labels: {
          formatter: function (value: number) {
            return Number.isInteger(value) ? value.toString() : value.toFixed(1);
          }
        }
      },
      yaxis: {
        title: { text: 'ค่าความเครียด (Stress)' },
        labels: {
          formatter: function (value: number) {
            return Number.isInteger(value) ? value.toString() : value.toFixed(2);
          }
        }
      },
      legend: {
        position: 'top'
      },
      tooltip: {
        custom: function ({
          seriesIndex,
          dataPointIndex,
          w
        }: {
          seriesIndex: number;
          dataPointIndex: number;
          w: any;
        }) {
          let heartRate = w.globals.seriesX[seriesIndex][dataPointIndex];
          let stress = w.globals.series[seriesIndex][dataPointIndex];
          stress = Number.isInteger(stress) ? stress.toString() : stress.toFixed(2);
          return `
            <div style="padding: 5px; background: white; border: 1px solid #ccc; border-radius: 5px;">
              <strong>ความเครียดเทียบกับอัตราการเต้นของหัวใจ (Stress vs Heart Rate)</strong><br />
              (${stress} / ${heartRate})
            </div>
          `;
        }
      }
    }
  }

  formatDateISO(Datetime: string) {
    let datetime: string = new Date(new Date(Datetime).getTime() + (3600000 * 7)).toISOString()
    return `${datetime.split('.')[0].replace('T', ' ').substring(0, 16)}`
  }

  closeModal() {
    this.md.emit(false)
  }
}
